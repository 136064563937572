import { FetchEventSourceInit, fetchEventSource } from '@microsoft/fetch-event-source';
import { getAuthToken, redirectToLogin } from './auth0Client';

export async function sseFetch(url: string, options: FetchEventSourceInit = {}): Promise<void> {
	try {
        const token = await getAuthToken();
        if (token) {
            options.headers = {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
            };
        }
    } catch (error) {
        console.error('Error retrieving token:', error);
        redirectToLogin();
    }

    const modifiedOptions: FetchEventSourceInit = {
        ...options,
        async onopen(response) {
            if (response.ok && response.headers.get('content-type')?.includes('text/event-stream')) {
                if (options.onopen) {
                    options.onopen(response);
                }
            } else {
                const json = await response.json();

                const message = json.message || json.error || json.err || JSON.stringify(json);

                throw new Error(message);
            }
        },
        onerror(err) {
            if (options.onerror) {
                options.onerror(err);
            }
        }
    };

	return await fetchEventSource(url, modifiedOptions);
}