import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import auth0Config from "../../config/auth0Config";
import { useAuth0Lang } from "../../utils/useAuth0Lang";

const PrivateRoute = ({ children }: any) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const getLangCode = useAuth0Lang();
    const location = useLocation();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            console.log("Logging in:")
            loginWithRedirect({
                appState: { returnTo: location.pathname },
                authorizationParams: {
                    scope: auth0Config.AUTH0_SCOPE,
                    ui_locales: getLangCode(),

                },
            })
        }
    }, [isLoading, isAuthenticated, location, loginWithRedirect, getLangCode]);

    return isAuthenticated ? children : null;
};

export default PrivateRoute;
