import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Text, useToast } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { pendingFileIdForCutting } from '../../atoms/rootAtom';
import { ClientError } from '../../utils/clientError';
import { UserFileDataService } from '../../services/userFileDataService';
import { useTranslation } from 'react-i18next';

type CuttingFileToastProps = {
    refetchDirectoryContent: () => void;
}

const CuttingFileToast: React.FC<CuttingFileToastProps> = ({ refetchDirectoryContent }) => {
    const [isMyDrivePath, setIsMyDriverPath] = useState<boolean>(false);
	const [fileOnCutting, setFileOnCutting] = useRecoilState(pendingFileIdForCutting);
	const { t } = useTranslation();
    const toast = useToast();

    const checkUrl = () => {
        // Check if the current URL contains "my-library"
        if (window.location.href.includes('my-library')) {
            setIsMyDriverPath(true);
        } else {
            setIsMyDriverPath(false);
        }
    };

    useEffect(() => {
        // Run checkUrl on component mount
        checkUrl();
    })

    const handleCancelPasting = () => setFileOnCutting({
        ...fileOnCutting,
        id: '',
        name: ''
    });

    const handlePasteHere = async () => {
        const params = new URLSearchParams(window.location.search);
        const dirId = params.get('directoryId');

        try {
            await UserFileDataService.updateDirectory(fileOnCutting.id, parseInt(dirId));
            refetchDirectoryContent();
            toast({
                title: `${fileOnCutting.name} ${t('paste-successful-label')}`,
                status: "success",
                duration: 2000,
            });
        } catch (err) {
            new ClientError(err).toast();
        }

        setFileOnCutting({
            ...fileOnCutting,
            id: '',
            name: ''
        });
    }

    return (
        <Center position={'fixed'} zIndex={200} bottom={'20px'} right={'20px'}>
            {isMyDrivePath && fileOnCutting.id !== '' &&
                <Flex justifyContent={'center'} alignItems={'center'} flexDir='row' w={'full'}  background={'white'} mt={4} p={4} borderWidth="1px" borderRadius="md" borderColor="gray.200" border={'5px dotted black'} >
                    <Flex flexDir={'row'} alignItems={'center'} pr={12}>
                        <Text fontSize={'xl'} fontWeight='bold' mr={3}>
                            {fileOnCutting.name}
                        </Text>
                        {t('is-cut-label')}
                    </Flex>
                    <Box>
                    <Button mr={2} onClick={handleCancelPasting}>
                        {t('cancel-paste-label')}
                    </Button>
                    <Button colorScheme="yellow" onClick={handlePasteHere}>
                        {t('paste-here-label')}
                    </Button>
                    </Box>
                    
                </Flex>
            }

        </Center>
    )
}

export default CuttingFileToast;