export function getSummaryToUse(file: { dooyeedSummary: string; summary: string }) {
	const doesDooyeedSummaryExist = file?.dooyeedSummary != null && file.summary !== "";
	const doesSummaryExist = file?.summary != null && file.summary !== "";

	if (doesDooyeedSummaryExist) {
		return file.dooyeedSummary;
	} else if (doesSummaryExist) {
		return file.summary;
	} else {
		return "";
	}
}
