import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { appLoadingState, appLoadingText } from "../atoms/rootAtom";

export const Loading = ({ isLoading = false }) => {
	const isAppLoading = useRecoilValue(appLoadingState);
	const loadingText = useRecoilValue(appLoadingText);

	return (
		<>
			{(isAppLoading || isLoading) &&
				<Box position={'fixed'} top={0} height="100%" width="100%" alignItems="center" justifyContent="center" zIndex={99} background={'blackAlpha.500'} color={'black'}>
					<Center height="100%" width="100%" >
						<Flex flexDir='row' color={'white'} alignItems={'center'}>
							<Spinner
								thickness="4px"
								speed="0.65s"
								emptyColor="gray.500"
								size="md"
								mr={'2'}
							/>
							<Text fontSize='md' color={'white'} className='conversation-loding'>{loadingText} </Text>
						</Flex>

					</Center>
				</Box>
			}
		</>
	);
};
