import { UserDataService } from "./userDataService";
import { formatDate } from "../utils/dateTimeConversion";


export class NotesService {
    public static async addNotes(note: string) {
        let currentNote = '';
        try {
            const response = await UserDataService.getUserData();
            currentNote = response.note;
        } catch (err) {
            console.log(err)
        }

        const formattedContent = currentNote + `
            <p>${note}</p>
            <p style='font-size: 10px; color: #ccc;'>Added at ${formatDate(new Date())}</p>
            <p></p>
        `
        try {
            await UserDataService.updateUserData(formattedContent);
        } catch (err) {
            console.log(err)
        }
    }
}