import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

interface Props {
	children: string;
}

const LatexSupportedText = ({ children }: Props) => {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkMath]}
			rehypePlugins={[rehypeKatex]}
		>
			{children}
		</ReactMarkdown>
	);
};

export default LatexSupportedText;