import { getUserId } from "./auth0Client";

export const auth0Plugin = {
	name: "auth0",
	track: async ({ payload }) => {
		try {
			const userId = await getUserId();
			if (userId) {
				const hashedUserId = await hashString(userId);

				// Add hashed user ID to the event payload
				payload.properties = {
					...payload.properties,
					hashedUserId: hashedUserId,
				};
			}
		} catch (error) {
			console.error("Error hashing user ID:", error);
		}

		return payload;
	},
};

async function hashString(str) {
	const hashBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
	return hashHex;
}
