import axios from "axios";

import { BASE_URL } from ".";
import { FileDetails } from "../types/FileDetails";
import { FileProcessingResponse } from "../types/FileProcessingStatus";
import { FileUploadResponse } from "../types/FileUploadResponse";
import { Sentences } from "../types/Sentence";

export interface EmbeddingJsonFile {
	[key: string]: {
		embedding_dict: any,
		sentence_embedding_dict: any,
		sentences_dict: {
			[key: number]: string
		}
	}
}

export interface RankingSentenceData {
	[key: string]: string[][] | number[][]
}

export interface SentencesMappingType {
	[key: string]: {
		[key: number]: string[]
	}
}

export type HashValueResponse = {
	isDuplicate: boolean
	existingFileId?: string
}

export class FileService {
	public static async downloadFile(id: string) {
		const response = await axios.get(BASE_URL + "/files/" + id, { responseType: "blob" });
		return response.data;
	}

	public static async getFilesDetails(ids: string[]): Promise<FileDetails[]> {
		const response = await axios.post(BASE_URL + "/files/details", { fileIds: ids });
		return response.data;
	}

	public static async checkFilesProcessingStatus(ids: string[]): Promise<FileProcessingResponse> {
		const response = await axios.post(BASE_URL + "/files/processing", { fileIds: ids });
		return response.data;
	}

	public static async uploadFiles(files: File[], directoryId: number | null): Promise<FileUploadResponse[]> {
		const formData = new FormData();
		files.forEach((file) => {
			formData.append("files", file);
		});

		if (directoryId) {
			formData.append("directoryId", directoryId.toString());
		}

		const response = await axios.post(BASE_URL + "/files", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return response.data;
	}

	public static async downloadHtmlFile(id: string): Promise<string> {
		const response = await axios.get(BASE_URL + '/files/html/' + id);
		return response.data;
	}

	public static async downloadCNHtmlFile(id: string): Promise<string> {
		const response = await axios.get(BASE_URL + '/files/html/cn/' + id);
		return response.data;
	}

	public static async getSentenceRank(id: string) {
		const response = await axios.get<number[]>(BASE_URL + "/files/rank/" + id);
		return response.data;
	}

	public static async getSentences(id: string) {
		const response = await axios.get<Sentences>(BASE_URL + "/files/sentences/" + id);
		return response.data;
	}

	public static async verifyIfArticleExisting(article: File): Promise<HashValueResponse> {
		const formData = new FormData();
		formData.append("file", article);
		const response = await axios.post(BASE_URL + '/files/hashvalue', formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		})
		return response.data;
	}
}