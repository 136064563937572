import { useAuth0 } from "@auth0/auth0-react";
import auth0Config from "../config/auth0Config";

const useAuthFunctions = () => {
	const { loginWithRedirect, logout } = useAuth0();

	const handleLogin = () => {
		loginWithRedirect({
			appState: { returnTo: '/reader' },
			authorizationParams: {
				scope: auth0Config.AUTH0_SCOPE,
				// ui_locales: getLangCode(),
			},
		});
	};

	const handleLogout = () => {
		logout({ logoutParams: { returnTo: window.location.origin } });
	};

	const handleSignup = () => {
		loginWithRedirect({
			authorizationParams: {
				screen_hint: "signup",
				scope: auth0Config.AUTH0_SCOPE,
				// ui_locales: getLangCode(),
			},
			appState: {
				returnTo: '/reader',
			},
		});
	};

	return { handleLogin, handleLogout, handleSignup };
};

export default useAuthFunctions;