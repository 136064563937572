import { Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalFooter, Button } from "@chakra-ui/react";
import LogoutBtn from "../../AuthButtons/LogoutBtn";
import { useTranslation } from "react-i18next";

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const LogoutModal = ({ isOpen, onClose }: Props) => {
    const { t } = useTranslation();

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('logout-label')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>{t('logout-confirmation')}</Text>
				</ModalBody>
				<ModalFooter>
					<LogoutBtn />
					<Button variant="ghost" onClick={onClose}>
						{t('cancel-label')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default LogoutModal;
