import React from 'react';
import LatexSupportedText from './LatexSupportedText';
import { Box, Flex, Textarea } from '@chakra-ui/react';

const LatexTest = () => {
	const [text, setText] = React.useState('');

	return (
		<Flex h={"full"} direction="column" gap={5} padding={10}>
			<Textarea
				value={text}
				onChange={(e) => setText(e.target.value)}
				placeholder="Type some latex here..."
				size="lg"
				resize="vertical"
				minH="200px"
			/>
			<Box overflow={"auto"}>
				<LatexSupportedText>{text}</LatexSupportedText>
			</Box>
		</Flex>
	);
};

export default LatexTest;