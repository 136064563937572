import React from "react";
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Stack,
    Text,
    Image,
    VStack,
    Link
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "@chakra-ui/icons";

const CaseStudyPage: React.FC = () => {
    const { i18n } = useTranslation();

    return (
        <Box margin="auto" px="4">
            {i18n.language == "en" && (
                <VStack spacing={4} align="stretch">
                    <Heading as="h2" size="xl" textAlign="left" mt="4">
                        Doenba.ai in Action: Empowering Learning Experience 
                    </Heading>
                    <Text fontSize="lg">
                    This page showcases how Doenba.ai empowers students, researchers, and professionals to enhance their learning efficiency through real-world use cases. See how Doenba.ai can transform your learning experience!
                    </Text>
                    <Box py={5}>
                        <Card
                            direction={{ base: 'column', sm: 'row' }}
                            overflow='hidden'
                            variant='outline'
                            mb={5}
                        >
                            <Image
                                objectFit='cover'
                                maxW={{ base: '100%', sm: '200px' }}
                                src='/doenba_case_study_1_cover.png'
                                alt='Case Study 1'
                            />

                            <Stack>
                                <CardBody>
                                    <Heading size='md'>Interpretation of the "Tao Te Ching"</Heading>

                                    <Text py='2'>
                                    Interpretation of the "Tao Te Ching"
                                    </Text>
                                </CardBody>

                                <CardFooter>
                                    <Link href='https://public-librum-files.s3.amazonaws.com/Doenba_case_study_1.pdf' isExternal>
                                        Download the study to learn more <DownloadIcon mx='2px' />
                                    </Link>
                                </CardFooter>

                            </Stack>
                        </Card>
                        
                        <Card
                            direction={{ base: 'column', sm: 'row' }}
                            overflow='hidden'
                            variant='outline'
                            mb={5}
                        >
                            <Image
                                objectFit='cover'
                                maxW={{ base: '100%', sm: '200px' }}
                                src='/doenba_case_study_2_cover.png'
                                alt='Case Study 1'
                            />

                            <Stack>
                                <CardBody>
                                    <Heading size='md'>Interpretation of the "Diamond Sutra"</Heading>

                                    <Text py='2'>
                                    Interpretation of the "Diamond Sutra"
                                    </Text>
                                </CardBody>

                                <CardFooter>
                                    <Link href='https://public-librum-files.s3.amazonaws.com/Doenba_case_study_2.pdf' isExternal>
                                        Download the study to learn more <DownloadIcon mx='2px' />
                                    </Link>
                                </CardFooter>

                            </Stack>
                        </Card>
                    </Box>
                </VStack>
            )}
            {i18n.language == "cn" && (
                <VStack spacing={4} align={"stretch"}>
                    <Heading as="h2" size="xl" textAlign="left" mt="4">
                    Doenba.ai 助力学习：激活高效学习体验
                    </Heading>
                    <Text fontSize="lg">
                    本页面通过真实案例展示 Doenba.ai 如何帮助学生、研究人员和专业人士提高学习效率。快来看看 Doenba.ai 如何转变您的学习体验吧！
                    </Text>
                    <Box py={5}>
                        <Card
                            direction={{ base: 'column', sm: 'row' }}
                            overflow='hidden'
                            variant='outline'
                            mb={5}
                        >
                            <Image
                                objectFit='cover'
                                maxW={{ base: '100%', sm: '200px' }}
                                src='/doenba_case_study_1_cover.png'
                                alt='Case Study 1'
                            />

                            <Stack>
                                <CardBody>
                                    <Heading size='md'>《道德经》解读</Heading>

                                    <Text py='2'>
                                    《道德经》解读
                                    </Text>
                                </CardBody>

                                <CardFooter>
                                    <Link href='https://public-librum-files.s3.amazonaws.com/Doenba_case_study_1.pdf' isExternal>
                                        下载案例 了解更多 <DownloadIcon mx='2px' />
                                    </Link>
                                </CardFooter>
                            </Stack>
                        </Card>

                        <Card
                            direction={{ base: 'column', sm: 'row' }}
                            overflow='hidden'
                            variant='outline'
                            mb={5}
                        >
                            <Image
                                objectFit='cover'
                                maxW={{ base: '100%', sm: '200px' }}
                                src='/doenba_case_study_2_cover.png'
                                alt='Case Study 1'
                            />

                            <Stack>
                                <CardBody>
                                    <Heading size='md'>《金刚经》解读</Heading>

                                    <Text py='2'>
                                    《金刚经》解读
                                    </Text>
                                </CardBody>

                                <CardFooter>
                                    <Link href='https://public-librum-files.s3.amazonaws.com/Doenba_case_study_2.pdf' isExternal>
                                        下载案例 了解更多 <DownloadIcon mx='2px' />
                                    </Link>
                                </CardFooter>
                            </Stack>
                        </Card>
                    </Box>
                </VStack>
            )}
        </Box>
    );
};

export default CaseStudyPage;