import { useTranslation } from "react-i18next";

const languages = {
	'en': 'en',
	'cn': 'zh-CN',
}

export function useAuth0Lang() {
	const { i18n } = useTranslation();

	return function get() {
		return languages[i18n.language as keyof typeof languages] || 'en';
	}
}