import axios from "axios";

import { BASE_URL } from ".";
import { TopicSearchHighlightResponse } from "../types/TopicSearch";

const topicSearchUrl = BASE_URL + "/topicSearch";

export class TopicSearchService {
	public static async getTopicSearchHistory(articleId: string): Promise<string[]> {
		const response = await axios.get(topicSearchUrl + '/' + articleId);
		return response.data;
	}

	public static async getTopicSearchResults(articleId: string, message: string): Promise<TopicSearchHighlightResponse> {
		const response = await axios.post(topicSearchUrl + '/' + articleId, { message });
		return response.data;
	}
}