import { Container, VStack, Heading, Text, UnorderedList, ListItem, Tag, Box, Flex, IconButton, Link as ChakraLink } from "@chakra-ui/react";
import { BiLeftArrow } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";


const PrivacyPolicy = () => {
    return (
        <Container maxW='container.xl' pt={12}>
            <VStack spacing={4} align="stretch" overflow={'auto'} h={'90vh'}>
                <Flex flexDir={'row'} alignItems={'center'}>
                    <Box>
                        <ChakraLink as={RouterLink} to='/'>
                            <IconButton aria-label='go back to home page' variant={'ghost'} icon={<BiLeftArrow />} />
                        </ChakraLink>
                    </Box>
                    <Heading as="h2" size="xl" textAlign="left" my="4">
                    Privacy Policy
                        </Heading>
                </Flex>
                <Tag maxW={'200px'} color={'gray.500'}>Last Updated: July 21, 2024</Tag>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Introduction
                </Heading>
                <Text fontSize="lg">
                    Doenba.ai (“we”, “us”, or “our”) respects the privacy of our users (“you” or “your”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Information Collection
                </Heading>
                <Text fontSize="lg">
                    When you use our Service, we may collect the following information:
                </Text>
                <UnorderedList spacing={3} fontSize="lg">
                    <ListItem>Submitted Content: We collect the written text you submit for analysis.</ListItem>
                    <ListItem>Account Information: We may collect the name, email address, and other contact information you provide when you create an account.</ListItem>
                    <ListItem>Usage Data: We collect information on how the Service is accessed and used.</ListItem>
                </UnorderedList>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Use of Information
                </Heading>
                <Text fontSize="lg">
                    The information we collect is only used to:
                </Text>
                <UnorderedList spacing={3} fontSize="lg">
                    <ListItem>Provide and improve the Service.</ListItem>
                    <ListItem>Notify you about changes to our Service.</ListItem>
                    <ListItem>Provide customer support.</ListItem>
                    <ListItem>Monitor the usage of the Service.</ListItem>
                    <ListItem>Detect, prevent, and address technical issues.</ListItem>
                </UnorderedList>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Sharing of Information
                </Heading>
                <Text fontSize="lg">
                    We do not share your personal information with third parties, except as necessary to provide the Service or as required by law. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Data Security
                </Heading>
                <Text fontSize="lg">
                    We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Your Rights
                </Heading>
                <Text fontSize="lg">
                    You have the right to:
                </Text>
                <UnorderedList spacing={3} fontSize="lg">
                    <ListItem>Access and receive a copy of the personal data we hold about you.</ListItem>
                    <ListItem>Request correction of any incomplete or inaccurate data we have about you.</ListItem>
                    <ListItem>Request deletion of your personal data under certain conditions.</ListItem>
                </UnorderedList>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Children’s Privacy
                </Heading>
                <Text fontSize="lg">
                    Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Changes to This Privacy Policy
                </Heading>
                <Text fontSize="lg">
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    Contact Us
                </Heading>
                <Text fontSize="lg">
                    If you have any questions about this Privacy Policy, please contact us at info@doenba.ai.
                </Text>
            </VStack>
        </Container>
    );
};

export default PrivacyPolicy;

