import { Heading, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface VerticalScrollerProps {
    texts: string[];
    isTitle?: boolean;
}

const VerticalScroller: React.FC<VerticalScrollerProps> = ({ texts, isTitle }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(1);
    const [animate, setAnimate] = useState(false); 
    const { t } = useTranslation();


    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false); 
                setCurrentIndex((currentIndex + 1) % texts.length); 
                setNextIndex((currentIndex + 2) % texts.length); 
            }, 100); 
        }, 8000); 

        return () => clearInterval(interval);
    }, [texts.length, currentIndex]);

    return (
        <div className={isTitle ? 'text-wrapper-title' : 'text-wrapper'} style={{ position: 'relative', height: isTitle ? '50px' : '80px', overflow: 'hidden' }}>
            <div
                style={{
                    transform: animate ? 'translateY(-100%)' : 'translateY(0)',
                    transition: 'transform 500ms ease-in-out',
                    position: 'absolute',
                    width: '100%',
                }}
            >
                {isTitle ?
                    <Heading as="h2" size="lg">
                        {t(texts[currentIndex])}
                    </Heading> :
                    <Text>{t(texts[currentIndex])}</Text>
                }
                {animate && (
                    <p style={{ position: 'absolute', top: '100%', width: '100%' }}>{texts[nextIndex]}</p>
                )}
            </div>
        </div>
    );
};

export default VerticalScroller;


