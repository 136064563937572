import { Box, Center, Input, VStack, useBreakpointValue, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { appLoadingState, appLoadingText, 
    articlePreviewModalOpenStatus,  
    duplicatedPendingArticleId, 
    holdingForUploadingFiles, 
    isQuickUploader, 
    // currentOpenedArticleIdState, selectedArticlesState, 
    uploadingFile, 
} from "../../atoms/rootAtom";
import { FileService } from "../../services";
import { ClientError } from "../../utils/clientError";
import useUnprocessedFileHandler from "../../hooks/useUnprocessedFileHandler";
// import { UserFileDataService } from "../../services/userFileDataService";
import { FileDetails } from "../../types/FileDetails";
import ProcessingIndicator from "../ProcessingIndicator";
import FileIsReadyTopPopUp from "../OracleReaderComponent/FileIsReadyTopPopUp";
import { useTranslation } from "react-i18next";

function QuickFileUploader() {
    const toast = useToast();
    const [isDragging, setIsDragging] = useState(false)
    const setAppLoadingState = useSetRecoilState(appLoadingState);
    const setLoadingText = useSetRecoilState(appLoadingText);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadedFile, setUploadingFile] = useRecoilState<FileDetails | null>(uploadingFile);
    const uploadRef = useRef<HTMLInputElement>(null);
    const { addNeededFilesToPoller } = useUnprocessedFileHandler();
    const isMobile = useBreakpointValue({ base: true, md: true, lg: true, xl: false });
    const setAriclePreviewModalOpenStatus = useSetRecoilState(articlePreviewModalOpenStatus);
    const setduplicatedPendingArticleId = useSetRecoilState(duplicatedPendingArticleId);
    const { t } = useTranslation();
    const setIsQuickUploaderComponent = useSetRecoilState(isQuickUploader);
    const setOnHoldArtilces = useSetRecoilState(holdingForUploadingFiles);

    // Effect hook to run after selectedFile changes
    useEffect(() => {
        if (selectedFile) {
            uploadFiles([selectedFile]);
        }
    }, [selectedFile]); 
    
    // const currentlySelectedArticle = useMemo(() =>
    //     selectedArticles.find((article) => article.id === currentOpenArticleId),
    //     [currentOpenArticleId, selectedArticles]
    // );

    // useEffect(() => {
    //     fetchFiles();
    // }, [uploadedFile]);

    // const fetchFiles = async () => {
    //     try {
    //         const files = await UserFileDataService.getDriveFiles();
    //         setFiles(files);
    //     } catch (error) {
    //         new ClientError(error).toast();
    //     }
    // }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            event.target.value = "";
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = () => {
        setIsDragging(false);
    }

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);

        // Assuming the files are in DataTransfer object
        const files = event.dataTransfer.files;
        
        // Check if more than one file was dropped, if so, alert and ignore extra files
        if (files.length > 1) {
            alert("Please drop only one file.");
            return;  // Skip processing further
        }

        // Assuming handleFileChange processes the file
        if (files.length === 1) {
            uploadFiles(Array.from(files));
        }
    }

    const handleButtonClick = () => {
        if (uploadRef?.current)
            uploadRef.current.click();
    };

    async function getHashValue(file: File) {
        try {
            const response = await FileService.verifyIfArticleExisting(file);
            return response;
        } catch (e) {
            new ClientError(e).toast();
        }
    }

    const uploadFiles = async (files: File[]) => {
        setAppLoadingState(true);
        setLoadingText('File uploading');

        const foundExists = await getHashValue(files[0]);
                if (foundExists.isDuplicate) {
                    setIsQuickUploaderComponent(true);
                    setOnHoldArtilces(files);
                    setAriclePreviewModalOpenStatus(true);
                    setduplicatedPendingArticleId(foundExists.existingFileId);
                    setAppLoadingState(false);
                } else {
                    try {
                        const response = await FileService.uploadFiles(files, null);
                        for (const file of response) {
                            if (file.isSuccessful) {
                                setUploadingFile(file.file);
                                addNeededFilesToPoller(file.file);
                            } else {
                                let message = "Error uploading file: " + file.identifier;
                                if (file.error && typeof file.error === 'string') {
                                    message += ". " + file.error;
                                }
            
                                toast({
                                    title: "File Upload Error",
                                    description: message,
                                    status: "error",
                                });
                            }
                        }
                    } catch (error) {
                        new ClientError(error).toast();
                    } finally {
                        setAppLoadingState(false);
                    }
                }  
    }

    return (
        <Box pos={'relative'}>
            <VStack w={'100%'} h={'100%'}>
                {!uploadedFile &&
                    <>
                        <Input
                            ref={uploadRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <Center 
                            w={'100%'}
                            h={'100%'}
                            py={1.5}
                            border={'2px dashed #ccc'}
                            borderRadius={5}
                            bgColor={isDragging ? 'gray.300' : 'gray.100'}
                            onClick={handleButtonClick} 
                            cursor={'pointer'}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            fontSize={'sm'}
                            color={'gray.500'}
                            >
                            {!isMobile ? t('quick-uploader-full-label') : t('quick-uploader-short-label')}
                        </Center>
                    </>
                }
                {uploadedFile && 
                    <Box w={'100%'} pos={'relative'} borderRadius={'10px'}>
                        <ProcessingIndicator file={uploadedFile} isQuickFileUploader />
                    </Box>
                }
            </VStack>
            <FileIsReadyTopPopUp />
        </Box>
    );
}

export default QuickFileUploader;