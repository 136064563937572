import { Flex, FormControl, FormLabel, Tooltip, Text, Switch, Button } from "@chakra-ui/react";
import { TbBulbFilled } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "use-analytics";

interface Props {
	isEnabled: boolean;
	setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	text: string | null;
	setText: React.Dispatch<React.SetStateAction<string | null>>;
	onGetAnswerClick: () => void;
}

const Inspiration = ({ isEnabled, setIsEnabled, text, setText, onGetAnswerClick }: Props) => {
	const { t } = useTranslation();
	const { track } = useAnalytics();
	const shouldShowTextAndButton = isEnabled && text != null;

	const trackYesNoClick = (answer: string, text: string) => {
		track("inspiration_yes_no_click", { answer, text });
	}

	const handleYesClick = () => {
		trackYesNoClick("yes", text);
		onGetAnswerClick();
	}

	const handleNoClick = () => {
		trackYesNoClick("no", text);
		setText(null);
	}

	return (
		<>
			<Flex
				justifyContent="space-between"
				gap={5}
				alignItems="center"
				borderWidth={shouldShowTextAndButton ? 1 : 0}
				borderColor="gray.500"
				borderStyle="dashed"
				borderRadius={"10px 10px 0 0 "}
				p={2}
				pt={4}
				w={"full"}
			>
				<Flex flexDirection="column" w={"full"}>
					<Tooltip label={t("inspiration-message")}>
						<FormControl display="flex" alignItems="center" w={"210px"}>
							<FormLabel htmlFor="inspiration" mb="0">
								<Flex flexDir={"row"} alignItems={"center"}>
									<TbBulbFilled color={isEnabled ? "orange" : "black"} />
									<Text ml={"2"}>{t("enable-inspiration-label")}</Text>
								</Flex>
							</FormLabel>
							<Switch
								id="Inspiration"
								size={"sm"}
								colorScheme="yellow"
								isChecked={isEnabled}
								onChange={(e) => setIsEnabled(e.target.checked)}
							/>
						</FormControl>
					</Tooltip>
					{shouldShowTextAndButton && (
						<Flex p={2} direction="column">
							<Text pt={2} pb={4} color="gray.600">
								{text}
							</Text>
							<Flex
								direction="row"
								mt={2}
								borderTop="1px solid"
								paddingTop={2}
								justifyContent={"space-between"}
								alignItems={"center"}
							>
								<Text textAlign="right">{t("get-answer-label")}</Text>
								<Flex gap={2} justifyContent="right">
									<Button
										colorScheme="yellow"
										border={"1px solid black"}
										onClick={handleYesClick}
									>
										{t("yes-label")}
									</Button>
									<Button onClick={handleNoClick}>{t("no-label")}</Button>
								</Flex>
							</Flex>
						</Flex>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default Inspiration;
