import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Image,
  ListItem,
  UnorderedList,
  OrderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LatexSupportedText from "../components/LatexSupportedText";

const About: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <Box margin="auto">
      {i18n.language == "en" && (
        <VStack spacing={4} align="stretch">
          <Heading as="h2" size="xl" textAlign="left" my="4">
            Reliable AI for Learning from Written Materials
          </Heading>
          <Text fontSize="lg">
            For text AI to truly help us learn more effectively from written materials, it must accurately and consistently answer our inquiries and satisfy our curiosities using the written materials as ground truth, and express its responses in language that is clear, structured, and understandable.
          </Text>
          <Text fontSize="lg">
            Striving towards this ideal of accurate and insightful AI assistance, Doenba empowers users to become master learners from any written material through a suite of tools that:
          </Text>
          <UnorderedList spacing={3} fontSize="lg">
            <ListItem>
              Craft custom summaries to help you get the gist of the article quickly and easily according to your specifications.
            </ListItem>
            <ListItem>
              Highlight your inquired topics to help you focus on the information that interests you most.
            </ListItem>
            <ListItem>
              Answer your inquiries and provide insightful responses to help you gain a deeper understanding of the article.
            </ListItem>
            <ListItem>
              Spark inspiration to help you explore related ideas and expand your understanding.
            </ListItem>
          </UnorderedList>
          <Text fontSize="lg">
            Moreover, Doenba can also help you discover new articles related to your studies or areas of interest, even if you don’t use the exact keywords in your search. This “semantic search” functionality ensures you find relevant documents, rather than those that match keywords but are semantically irrelevant. Additionally, Doenba helps you organize and manage your study sessions and learning materials, making the learning process smoother and more efficient.
          </Text>
          <Text fontSize="lg">
            At the core of Doenba is the concept and technologies of LLM-oracle machines (LLM-OM), where LLM stands for large language model. Both queries and responses are expressed in natural language, encompassing formatting. Since different LLMs are trained on diverse datasets using different technologies, their capabilities vary. Therefore, we use a cluster of LLMs as the oracle.
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              The input to an LLM-OM $$M$$ is a pair $$(T,Q)$$, where $$T$$ is a PDF document or a collection of PDF documents serving as the ground truth the computation must adhere to, and $$Q$$ is a task to be accomplished. Both $$T$$ and $$Q$$ are expressed in natural language. The output $$A$$ is a solution to $$Q$$ with respect to $$T$$.</LatexSupportedText>
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              During the computation, $$M$$ generates queries to the LLM-oracle adaptively or nonadaptively with each query in the form of $$(x; y_1, y_2, . . . , y_k)$$, denoted as $$q_x$$, where $$x$$ is an intermediate task toward a solution to $$Q$$ and each $$y_i$$ is an attribute that can be</LatexSupportedText>
          </Text>

          <UnorderedList spacing={3} fontSize="lg">
            <ListItem>
              <LatexSupportedText>
                Selected text to provide relevant context for $$x$$.
              </LatexSupportedText>
            </ListItem>
            <ListItem>
              <LatexSupportedText>
                Specific requirements to detail the desired outcome of $$x$$.
              </LatexSupportedText>
            </ListItem>
            <ListItem>
              <LatexSupportedText>
                Solution format to instruct $$M$$ on how to express the answer (e.g., text, code).
              </LatexSupportedText>
            </ListItem>
            <ListItem>
              Verification methods to specify how to validate the answer's correctness.
            </ListItem>
            <ListItem>
              <LatexSupportedText>
                Self-critique instructions to guide $$M$$ to evaluate its own repsonse.
              </LatexSupportedText>
            </ListItem>
            <ListItem>
              <LatexSupportedText>
                Other specifications with additional details relevant to completing $$x$$.
              </LatexSupportedText>
            </ListItem>
          </UnorderedList>

          <Text fontSize="lg">
            <LatexSupportedText>
              Each LLM acts as a query interpreter and problem solver, first interpreting the query $$q_x$$ to understand exactly what is being asked, and then using the best-matched content from the ground truth $$T$$, identified by various algorithms, along with its language understanding and inference abilities, to generate the optimal solution to the query $$q_x$$.</LatexSupportedText>
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              Various algorithms and measures are employed, striving to meet the following requirements:
            </LatexSupportedText>
          </Text>
          <OrderedList>
            <ListItem>
              <Text fontSize="lg">
                <LatexSupportedText>
                  For any input  $$(T,Q)$$ , $$M$$ generates a correct solution $$A$$ to $$Q$$ relative to $$T$$, meaning that the following conditions are satisfied:
                </LatexSupportedText>
              </Text>
              <UnorderedList spacing={3} fontSize="lg">
                <ListItem>
                  <LatexSupportedText>
                    Compliance: $$topic(Q) ⊆ topic(A)$$ and $$meaning(A) ⊆ meaning(T)$$.
                  </LatexSupportedText>
                </ListItem>
                <ListItem>
                  <LatexSupportedText>
                    Exclusiveness: $$meaning(A) ⊆ intent(Q)$$.
                  </LatexSupportedText>
                </ListItem>
                <ListItem>
                  <LatexSupportedText>
                    Completeness: $$[meanging(T) − meaning(A)] ∩ intent(Q) = ∅$$.
                  </LatexSupportedText>
                </ListItem>
                <ListItem>
                  <LatexSupportedText>
                    Distribution Agreement: Content distribution of $$A$$ is similar to that of $$T$$ relevant to $$Q$$ and $$A$$.
                  </LatexSupportedText>
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              <Text fontSize="lg">
                <LatexSupportedText>
                  Answers are consistent, meaning that for any task-solution pairs $$(Q,A)$$ and $$(Q',A')$$ with the same ground-truth $$T$$, if $$intent(Q) = intent(Q')$$ in different runs, then $$meaning(A) = meaning(A')$$.
                </LatexSupportedText>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg">Answers are <b>well presented</b>, meaning they are structured, layered, and properly formatted.</Text></ListItem>
          </OrderedList>
          <Text fontSize="lg">
            <LatexSupportedText>
              We have developed and employed a series of algorithms and in-house trained models to interactively compute and generate accurate and succinct answers by appropriately utilizing the characteristics of different types of LLMs. For instance, Doenba employs contextual networks for representing articles, content-significance distributions for contents within articles, ranking algorithms of nodes in a contextual network, topic analysis, and 0-1 knapsack optimization to rank sentences in the ground truth $$T$$. This aids in identifying the best-matched contents for a given task. Additionally, Doenba uses AI WordNeter to automatically integrate new terms into the language’s hierarchical semantics network, thereby enhancing semantic search capabilities.
            </LatexSupportedText>
          </Text>
          <Text fontSize="lg">
            Users can inquire about text contents, tables, and formulas within the ground-truth data. Questions may pertain to specific topics within a paper or encompass the entire paper. Moreover, Doenba enables the highlighting of relevant contents in the article for each inquiry on the given article displayed in the original layout and typeset of the uploaded PDF file.
          </Text>
          <Text fontSize="lg">
            To enhance your comprehension of the article, Doenba offers interactive insights tailored to your recent inquires, encouraging deeper and broader thinking. Additionally, to assist you in managing articles and study sessions, Doenba creates an individual private library for you. It also records your study sessions of inquiries and answers for each paper in the database, facilitating later reviews.
          </Text>
          <Text fontSize="lg">
            Depicted below is the system schematic of the LLM-oracle machine for Doenba.
          </Text>
          <Box my={10}>
            <Image src="/doenba_system_diagram_en.png"></Image>
          </Box>
        </VStack>

      )}
      {i18n.language == "cn" && (
        <VStack spacing={4} align={"stretch"}>
          <Heading as="h2" size="xl" textAlign="left" my="4">
            理解文本的可靠AI
          </Heading>
          <Text fontSize="lg">
            为了让文本AI真正帮助我们更有效地从书面材料中学习，它必须基于书面材料作为事实依据，准确且一致地解答我们的疑惑、满足我们的好奇心，并同时以清晰、结构化且易于理解的语言表达其回应。
          </Text>
          <Text fontSize="lg">
            为实现这一理想，Doenba为您提供了一组工具，帮您成为可从任何文章学习新知识的高效学习者。这些智能工具可帮助您完成以下任务：
          </Text>
          <UnorderedList spacing={3} fontSize="lg">
            <ListItem>
              根据您对摘要的具体要求创建自定义摘要，帮助您快速轻松地掌握文章要点。
            </ListItem>
            <ListItem>
              高亮您询问的主题内容，帮助您专注于最感兴趣的信息。
            </ListItem>
            <ListItem>
              对您的提问提供深入解答，帮助您更好地理解文章的内容。
            </ListItem>
            <ListItem>
              启发您的思维，帮助您探索相关想法并拓展您对相关内容的理解。
            </ListItem>
          </UnorderedList>
          <Text fontSize="lg">
            此外，Doenba还能帮助您查询与您的学习领域或其他您感兴趣的领域相关的最新文章，即使您在搜索中没有使用完全匹配的关键词。这项“语义搜索”功能可以助您快速找到相关文档而不是一系列关键词匹配但意思无关的文档。Doenba还可帮您管理文章及您的提问和系统的回答，使您的学习过程更加流畅高效。
          </Text>
          <Text fontSize="lg">
            Doenba的核心概念和技术是LLM-oracle machine（简称为LLM-OM），其中LLM代表大语言模型。我们将Doenba想象成一位智者，通过咨询某位或多位专家为您的提问提供最佳解答。LLM-OM 通过与LLM 交互实现类似的效果。其工作原理如下：LLM-OM使用不同的算法和模型来识别文章中与当前提问最匹配的内容，并利用一个或多个LLM来回答您的提问，包括具有复杂逻辑的提问。
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              记Doenba LLM-OM为$$M$$，输入为$$(T, Q)$$，其中$$T$$是作为基准的PDF文档或一组PDF文档，$$Q$$是表示需要完成的任务。$$T$$和$$Q$$均以自然语言表示。输出是一个符合基准$$T$$的执行任务$$Q$$的答案。
            </LatexSupportedText>
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              在计算中$$M$$根据需要动态或静态生成若干查询，每个查询表示成$$(x; y_1, y_2, \ldots, y_k)$$，简记为$$q_x$$，其中$$x$$是某个中间任务，每个$$y_i$$是一个属性，用于表示完成$$x$$从基准数据中选取的内容，具体要求、描述解决方案的表达方式、需要进行的验证、或某个其他说明。
            </LatexSupportedText>
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              每个LLM都充当查询解释器和完成查询中任务满足查询条件的解答器，首先解释查询$$q_x$$以准确理解其需求，然后利用各种算法识别出的与事实数据$$T$$最匹配的内容，并结合其语言理解和推理能力，生成对查询$$q_x$$的最佳解决方案。
            </LatexSupportedText>
          </Text>
          <Text fontSize="lg">
            <LatexSupportedText>
              我们采用多种算法和措施以满足以下要求：
            </LatexSupportedText>
          </Text>
          <OrderedList spacing={3} fontSize="lg">
            <ListItem>
              <Text fontSize="lg">
                <LatexSupportedText>
                  对于任何输入$$(T,Q)$$，$$M$$生成的解决$$Q$$的解答$$A$$相对于$$T$$是正确的，即满足以下条件
                </LatexSupportedText>
              </Text>
              <UnorderedList spacing={3} fontSize="lg">
                <ListItem>
                  <LatexSupportedText>
                    相关性: $$topic(Q) ⊆ topic(A)$$ 方案$$A$$及 $$meaning(A) ⊆ meaning(T)$$.
                  </LatexSupportedText>
                </ListItem>
                <ListItem>
                  <LatexSupportedText>
                    排他性: $$meaning(A) ⊆ intent(Q)$$.
                  </LatexSupportedText>
                </ListItem>
                <ListItem>
                  <LatexSupportedText>
                    完全性: $$[meanging(T) − meaning(A)] ∩ intent(Q) = ∅$$.
                  </LatexSupportedText>
                </ListItem>
                <ListItem>
                  <LatexSupportedText>
                    分布一致性: $$A$$内容分布与$$T$$中与$$Q$$和$$A$$相关的内容分布一致。
                  </LatexSupportedText>
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              <Text fontSize="lg">
                <LatexSupportedText>
                  $$M$$生成的答案是稳定的，即对于任何具有相同事实依据$$T$$的任务-解决方案对$$(Q,A)$$和 $$(Q',A')$$,如果在不同运行中：$$intent(Q)=intent(Q')$$，那么$$meaning(A)=meaning(A')$$.
                </LatexSupportedText>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg">M生成的答案<b>表述良好</b>，即结构清晰、层次分明、并符合格式规范。</Text></ListItem>
          </OrderedList>
          <Text fontSize="lg">
            我们开发并使用了一系列算法和内部训练的模型，通过合理利用不同类型的大型语言模型的特性，以交互计算方式计生成正确和简洁的答案。例如，Doenba采用上下文网络来表示文章、文章的内容重要性分布、上下文网络中节点的排序算法、主题分析和0-1背包优化对基准T中的句子进行排序。这有助于识别与给定任务最匹配的内容。此外，Doenba使用AI WordNeter自动将新术语集成到自然语言的层次语义网络中，从而增强语义搜索能力。
          </Text>
          <Text fontSize="lg">
            您可以查询基准数据中的任何内容，包括文字内容、表格内容和数学公式内容。提问可以涉及文中的某个专题，也可以涵盖整篇文章。此外，Doenba能够在在上传的PDF文件中高亮显示与您的提问相关的内容。
          </Text>
          <Text fontSize="lg">
            为了增强您对文章的理解，Doenba根据您的最近查询和基准内容提供启发建议，帮助您作更深入和更广泛的思考。此外，为了帮助您管理论文和学习记录，Doenba为您创建个人文库，并将每篇文章的查询和回答的学习记录保存在数据库中，方便您日后复习之用。
          </Text>
          <Text fontSize="lg">下面是Doenba系统的体系结构示意图。</Text>

          <Box my={10}>
            <Image src="/doenba_system_diagram_cn.png"></Image>
          </Box>
        </VStack>

      )}
    </Box>
  );
};

export default About;
