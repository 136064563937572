import { Box, IconButton, Link, Tooltip } from "@chakra-ui/react";
import { BiSupport } from "react-icons/bi";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "use-analytics";

interface SupportButtonProps {
	application: string;
}

function SupportButton({ application }: SupportButtonProps) {
	const { user, isAuthenticated } = useAuth0();
	const { track } = useAnalytics();
	const baseUrl = "https://support.dooyeed.ai";
	const params = new URLSearchParams();
	const { t } = useTranslation();

	if (isAuthenticated) {
		if (user.user_metadata.first_name && user.user_metadata.last_name) {
			params.append(
				"name",
				user.user_metadata.first_name + " " + user.user_metadata.last_name
			);
		} else {
			params.append("name", user.name);
		}
		params.append("email", user.email);
		params.append("application", application);
	}
	const link = `${baseUrl}?${params.toString()}`;

	return (
		<Box pos="fixed" bottom="10px" left="20px">
			<Link href={link} isExternal>
				<Tooltip label={t("support-center")} placement="left">
					<IconButton
						size="md"
						border="1px solid black"
						aria-label={t("support-center")}
						icon={<BiSupport />}
						onClick={() => track("support_button_click")}
					/>
				</Tooltip>
			</Link>
		</Box>
	);
}

export default SupportButton;
