import { useEffect } from 'react';
import { Box, Text, Heading, Flex, IconButton } from '@chakra-ui/react';
import { BiRefresh } from "react-icons/bi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNote } from '../../hooks/useNote';
//import { ClientError } from '../../utils/clientError';

const Note = () => {
    const {
        note, setNote,
        isDirty, setIsDirty,
        isLoading, saveNote
    } = useNote();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isDirty]);

    const handleNoteChange = (note: string) => {
        setNote(note);
        setIsDirty(true);
        saveNote(note);
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <>

                <Box boxShadow='lg' p='2' rounded='md' bg='white' border={'1px solid black'} maxW={'100vw'} height={'90vh'}>
                    <Flex justifyContent='space-between' alignItems={'center'} mb='2'>
                        <Heading size="sm">Notebook</Heading>
                        <Box>
                            <IconButton size={'xs'} mr='1' onClick={() => saveNote(note)} aria-label='Refresh' icon={<BiRefresh />} />
                        </Box>
                    </Flex>
                    <ReactQuill
                        style={{ height: 'calc(100% - 50px)', width: '100%', overflow: 'auto' }}
                        value={note}
                        onChange={handleNoteChange}
                    />
                    {isDirty && (
                        <Text color={'blackAlpha.600'} fontSize='xs'>Your notes will be auto saved shortly</Text>
                    )}
                </Box>

        </>
    );
};

export default Note;