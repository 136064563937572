import axios from "axios";

import { BASE_URL } from ".";
import { Search, SearchConversationHistory } from "../types/Search";
import { FileUploadResponse } from "../types/FileUploadResponse";

const url = BASE_URL + "/searches";

export class SearchService {
	public static async getSearches(): Promise<Search[]> {
		const response = await axios.get(url);
		return response.data;
	}

	// Endpoint for getting search history and results
	public static async getSearchInfo(id: string): Promise<SearchConversationHistory[]> {
		const response = await axios.get(url + "/" + id);
		return response.data;
	}

	public static async initSearch(): Promise<Search> {
		const response = await axios.post(url);
		return response.data;
	}

	public static async addToSearch(id: string, message: string): Promise<SearchConversationHistory[]> {
		const response = await axios.post(url + "/" + id, { message });
		return response.data;
	}

	public static async deleteSearch(id: string) {
		const response = await axios.delete(url + "/" + id);
		return response.data;
	}

	public static async uploadSearchResult(resultId: number): Promise<FileUploadResponse> {
		const response = await axios.post(url + "/upload-search-result/" + resultId);
		return response.data;
	}
}