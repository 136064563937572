import axios from "axios";
import { UserData } from "../types/UserData";

import { BASE_URL } from ".";

const url = BASE_URL + "/userData";

export class UserDataService {
	public static async getUserData(): Promise<UserData> {
		const response = await axios.get(url);
		return response.data;
	}

	public static async updateUserData(note: string): Promise<UserData> {
		const response = await axios.put(url, { note });
		return response.data;
	}
}