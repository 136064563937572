import axios from "axios";
import { BASE_URL } from ".";
import { Directory, DirectoryWithChildren } from "../types/Directory";

interface CreateUpdateDirectoryRequest {
	parentId: number | null;
	name: string;
}

export class FileDirectoryService {
	public static async getDirectoryContents(directoryId?: number) {
		const response = await axios.get<DirectoryWithChildren>(BASE_URL + "/fileDirectories" + (directoryId ? `/${directoryId}` : ""));
		return response.data;
	}

	public static async createDirectory(data: CreateUpdateDirectoryRequest): Promise<Directory> {
		const response = await axios.post(BASE_URL + "/fileDirectories/", data);
		return response.data;
	}

	public static async updateDirectory(directoryId: number, newDirectory: CreateUpdateDirectoryRequest): Promise<void> {
		await axios.put(BASE_URL + "/fileDirectories/" + directoryId, newDirectory);
	}

	public static async deleteDirectory(directoryId: number): Promise<void> {
		await axios.delete(BASE_URL + "/fileDirectories/" + directoryId);
	}
}