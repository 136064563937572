import { CloseIcon, ViewIcon } from "@chakra-ui/icons";
import {
	Text,
	Box,
	Button,
	Center,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
	isOpen: boolean;
	onClose: () => void;
	html: string;
	isLoading: boolean;
}

const FilePreviewModal = ({ isOpen, onClose, html, isLoading }: Props) => {
    const { t } = useTranslation();

	return (
		<Modal onClose={onClose} size={"full"} isOpen={isOpen}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				{isLoading && (
					<ModalBody>
						<Center w={"full%"} h={"full"}>
							<Spinner />
						</Center>
					</ModalBody>
				)}
				{!isLoading && (
					<ModalBody>
						<Box w={"100%"} h={"100%"}>
							<Box position={"absolute"} top="5px" left="25px" zIndex={1}>
								<Flex>
									<Flex
										flex="row"
										alignItems={"center"}
										bgColor={"white"}
										px="2"
										borderRadius={"5"}
										color={"gray.500"}
										mr="2"
									>
										<ViewIcon mr="1" />
										<Text fontSize={"sm"}>{t('preview-label')}</Text>
									</Flex>
									<Button size={"sm"} onClick={onClose}>
										<CloseIcon />
									</Button>
								</Flex>
							</Box>
							<Box w={"100%"} h={"calc(100% - 20px)"}>
								<div
									dangerouslySetInnerHTML={{
										__html: html,
									}}
								/>
							</Box>
						</Box>
					</ModalBody>
				)}
			</ModalContent>
		</Modal>
	);
};

export default FilePreviewModal;
