import { Card, CardBody, Heading, Stack, Box, Text, Flex, CardFooter, Menu, MenuButton, IconButton, MenuList, MenuItem } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Directory } from "../../types/Directory";
import { ChevronLeftIcon, DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";
import useDirectories from "../../hooks/useDirectories";

interface Props {
	directory: Directory;
	onMoveToParent: () => void;
	onDelete: () => void;
	onOpen: () => void;
}

const DirectoryCard = ({ 
	directory,
	onMoveToParent,
	onDelete,
	onOpen 
}: Props) => {
	const { t } = useTranslation();
	const { currentDirectory } = useDirectories();

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onDelete();
	}

	const handleMoveToParent = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onMoveToParent();
	}

	return (
		<Card
			width="100%"
			h="280px"
			backgroundSize={"100%"}
			borderLeft={"5px dotted black"}
			borderRight={"5px solid #999"}
			cursor={"pointer"}
			_hover={{ boxShadow: "5px 5px 5px #ccc", transition: "all 0.2s ease" }}
			onClick={onOpen}
			overflow={"hidden"}
			backgroundColor={'transparent'}
			borderRadius={'5px'}
		>
			<CardBody position="relative">
				<Stack mt="6" spacing="3">
					<Heading
						size="md"
						h={"50px"}
						textOverflow={"ellipsis"}
						overflow={"hidden"}
						whiteSpace={"nowrap"}
					>
						<Box
							as="div"
							display="-webkit-box"
							overflow="hidden"
							whiteSpace="normal"
							textOverflow="ellipsis"
							style={{
								WebkitLineClamp: "2",
								WebkitBoxOrient: "vertical",
							}}
							h={"50px"}
						>
							{directory.name}
							<Text mt={2} fontSize='sm' color={'blackAlpha.500'}>Folder</Text>
						</Box>
					</Heading>
				</Stack>
			</CardBody>
			<CardFooter>
				<Flex w="full" flexDir="row" alignItems="center" justifyContent="flex-end">
					<Box>
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label="Options"
								border={"1px solid black"}
								icon={<HamburgerIcon />}
								size={"sm"}
								onClick={(event) => event.stopPropagation()}
							/>
							<MenuList>
								<MenuItem
									icon={<DeleteIcon color={"red"} />}
									onClick={handleDelete}
								>
									{t("remove-label")}
								</MenuItem>
								{currentDirectory.id !== null && (
									<MenuItem
										icon={<ChevronLeftIcon color={"blue"} />}
										onClick={handleMoveToParent}
									>
										Move folder to {currentDirectory?.parent?.name || 'My Library'}
									</MenuItem>
								)}
							</MenuList>
						</Menu>
					</Box>
				</Flex>
			</CardFooter>
		</Card>
	);
};

export default DirectoryCard;
