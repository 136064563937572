import { Auth0Client } from '@auth0/auth0-spa-js';
import authConfig from "../config/auth0Config";

const auth0 = new Auth0Client({
    domain: authConfig.AUTH0_BASE_URL,
	clientId: authConfig.AUTH0_CLIENT_ID,
	authorizationParams: {
		redirect_uri: window.location.origin,
		audience: authConfig.AUTH0_AUDIENCE,
		scope: authConfig.AUTH0_SCOPE,
	},
});

export async function getAuthToken() {
    try {
        const token = await auth0.getTokenSilently();
        return token;
    } catch (error) {
        console.error('Error retrieving token:', error);
        throw error;
    }
}

export async function redirectToLogin() {
    try {
        await auth0.loginWithRedirect();
    } catch (error) {
        console.error('Error redirecting to login:', error);
        throw error;
    }
}

export async function getUserId() {
    try {
        await auth0.checkSession();
        const user = await auth0.getUser();
        return user?.sub;
    } catch (error) {
        console.error('Error retrieving user:', error);
        throw error;
    }
}