import { useEffect, useState } from "react";
import { FileDetails, SelectedFileDetails } from "../../types/FileDetails";
import { ExternalLinkIcon, Search2Icon } from '@chakra-ui/icons';
import {
    List,
    ListItem,
    ListIcon,
    Text,
    Flex,
    Input,
    InputGroup,
    Box,
    Alert,
    AlertIcon,
    AlertTitle,
    InputLeftElement,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    IconButton,
    useToast,
    Tooltip,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { MdFileCopy } from "react-icons/md";
import { useRecoilState, useSetRecoilState } from "recoil";
import { appLoadingState, currentOpenedArticleIdState, selectedArticlesState } from "../../atoms/rootAtom";
import { UserFileDataService } from "../../services/userFileDataService";
import { CiBookmarkRemove } from "react-icons/ci";
import { ClientError } from "../../utils/clientError";
import FeatureIcons from "../FeatureIcons";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ArticleList = () => {
    const [error, setErrors] = useState<boolean>(false);
    const [currentOpenArticleId, setCurrentOpenArticleId] = useRecoilState<string>(currentOpenedArticleIdState);
    const [selectedArticles, setSelectedArticles] = useRecoilState(selectedArticlesState);

    const [driveArticles, setDriveArticles] = useState<FileDetails[]>([]);
    const [localDriveArticles, setLocalDriveArticles] = useState<FileDetails[]>([]);
    const [readingListArticles, setReadingListArticles] = useState<FileDetails[]>([]);
    const [localReadingListArticles, setLocalReadingListArticles] = useState<FileDetails[]>([]);
    const setAppLoadingState = useSetRecoilState(appLoadingState);
    
    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const myDriveFiles = await UserFileDataService.getDriveFiles();
                const readingListFiles = await UserFileDataService.getReadingListFiles();
                setDriveArticles(myDriveFiles);
                setLocalDriveArticles(myDriveFiles);
                setReadingListArticles(readingListFiles);
                setLocalReadingListArticles(readingListFiles);
            } catch (error) {
                setErrors(true);
            }
        }
        fetchFiles();
    }, []);

    const searchArticle = (name: string) => {
        if (name.trim() !== '') {
            const filteredDriveArticles = driveArticles.filter(article => article.name.includes(name));
            const filteredReadingListArticles = readingListArticles.filter(article => article.name.includes(name));
            setLocalDriveArticles(filteredDriveArticles);
            setLocalReadingListArticles(filteredReadingListArticles);
        } else {
            setLocalDriveArticles(driveArticles);
            setLocalReadingListArticles(readingListArticles);
        }
    };

    const handleRowOnClick = async (file: FileDetails) => {
        const fileId = file.id;
        const isInOracleReader = location.pathname.includes('/reader');

        if (!isInOracleReader || !selectedArticles.some(file => file.id == fileId)) {
            // If not already selected
            setAppLoadingState(true);
            setSelectedArticles([file]);
            setCurrentOpenArticleId(fileId);

            if (!isInOracleReader) {
                navigate(`/reader`);
            }
        } else {
            // If already selected
            setSelectedArticles([]);
            setCurrentOpenArticleId('');
        }
    };

    return (
        <Flex
            width={'100%'}
            h='full'
            flexDir={'column'}
            pt={'5'}
        >
            <Box>
                <InputGroup colorScheme="blackAlpha" size={'md'}>
                    <InputLeftElement pointerEvents='none' >
                        <Search2Icon color='gray.300' />
                    </InputLeftElement>
                    <Input placeholder={t('search-articles-here')} colorScheme="blackAlpha" onChange={(e) => searchArticle(e.target.value)} borderRadius={0} />
                </InputGroup>
            </Box>
            <Box flex={'1 auto'}>
                <Accordion defaultIndex={[0, 1]} allowMultiple h={'100vh'} overflow={'auto'}>
                    <ArticleSubList
                        title={t('my-reading-list')}
                        handleRowOnClick={handleRowOnClick}
                        articles={localReadingListArticles}
                        setArticles={setLocalReadingListArticles}
                        selectedArticles={selectedArticles}
                        currentOpenArticleId={currentOpenArticleId}
                        error={error}
                        isReadingList={true}
                    />
                    <ArticleSubList
                        title={t('my-library-title')}
                        handleRowOnClick={handleRowOnClick}
                        articles={localDriveArticles}
                        selectedArticles={selectedArticles}
                        currentOpenArticleId={currentOpenArticleId}
                        error={error}
                        hasNavigation
                        navigateTo="/my-library"
                    />
                </Accordion>
            </Box>
        </Flex >
    );
}

interface ArticleSubListProps {
    title: string;
    handleRowOnClick: (file: FileDetails) => void;
    articles: FileDetails[];
    selectedArticles: SelectedFileDetails[];
    currentOpenArticleId: string;
    error: boolean;
    isReadingList?: boolean;
    hasNavigation?: boolean;
    navigateTo?: string;
    setArticles?: (file: FileDetails[]) => void;
}

const ArticleSubList = ({ title, handleRowOnClick, articles, currentOpenArticleId, error, isReadingList, setArticles, hasNavigation, navigateTo }: ArticleSubListProps) => {
    const toast = useToast();
    const { t } = useTranslation();

    const removeFromReadingList = async (fileId: string) => {
        try {
            await UserFileDataService.toggleReadingList(fileId);
            setArticles(articles.filter(item => item.id !== fileId));
            toast({
                title: "Success",
                description: `Article removed from reading list`,
                status: "success",
                duration: 3000,
            })
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    return (
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                        { hasNavigation ? 
                            <ChakraLink as={RouterLink} to={navigateTo}>
                                {title} <ExternalLinkIcon mx='2px' />
                            </ChakraLink> : <>{title}</>
                        }
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} p='0'>
                {!error &&
                    <List spacing={3}>
                        {articles.length !== 0 && articles.map((file) => (
                            <Tooltip
                                label={
                                    <FeatureIcons
                                        features={file.features}
                                        showTooltip={false}
                                    />
                                }
                                hasArrow
                                placement="left"
                                isDisabled={file.features == null || file.features.length === 0}
                                key={file.id}
                            >
                                <ListItem
                                    key={file.id}
                                    cursor={'pointer'}
                                    borderBottom={'1px solid #ccc'}
                                    borderTop={'1px solid #ccc'}
                                    bg={currentOpenArticleId == file.id ? 'gray.100' : 'gray.50'}
                                    mt={'-1px !important'}
                                >
                                    <Flex flexDir='row' p='3'>
                                        <Box>
                                            <Flex flexDir={'row'} alignItems={'center'} justifyContent={'center'}>
                                                {/* <Checkbox
                                                    colorScheme='gray'
                                                    value={file.id}
                                                    onChange={() => handleRowOnClick(file)}
                                                    isChecked={selectedArticles.some(selectedFile => selectedFile.id == file.id)}
                                                /> */}
                                            </Flex>
                                        </Box>
                                        <Flex
                                            direction='row'
                                            alignItems={'center'}
                                            pl={'2'}
                                            width="100%"
                                            onClick={() => handleRowOnClick(file)}
                                        >
                                            <ListIcon as={MdFileCopy} color='gray.500' />
                                            <Text fontSize='sm' overflow='ellipse'>{file.name}</Text >
                                        </Flex>
                                        {isReadingList &&
                                            <Box p={0} pr={'3'}>
                                                <Flex flexDir={'row'} alignItems={'center'} justifyContent={'center'}>
                                                    <Tooltip label={t('remove-article-label')}>
                                                        <IconButton size={'xs'} aria-label='Search database' icon={<CiBookmarkRemove />} onClick={() => removeFromReadingList(file.id)} />
                                                    </Tooltip>
                                                </Flex>
                                            </Box>
                                        }
                                    </Flex>
                                </ListItem>
                            </Tooltip>
                        ))}
                        {articles.length == 0 &&
                            <Box p={4} fontSize={'xs'} color={'lightgray'}>{t('no-article-been-added')} </Box>
                        }
                    </List>
                }
                {error &&
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>Files fetching error.</AlertTitle>
                    </Alert>
                }
            </AccordionPanel>
        </AccordionItem>
    );
}

export default ArticleList;
