import React from 'react';
import { useDrag } from 'react-dnd';
import { Box } from '@chakra-ui/react';

interface DraggableBoxProps {
  id: string;
  children: React.ReactNode;
}

const DraggableBox: React.FC<DraggableBoxProps> = ({ id, children }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOX',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }));

  return (
    <Box
      ref={drag}
      visibility={isDragging ? 'hidden' : 'visible'}
    >
      {children}
    </Box>
  );
};

export default DraggableBox;