import { Card, CardBody, CardFooter, Flex, Button, Box, Heading, SlideFade } from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentOpenedArticleIdState, popUpShowStatus, selectedArticlesState, uploadingFile as uploadingFileFromRecoil } from '../../atoms/rootAtom';
import { useEffect } from 'react';
import useUnprocessedFileHandler from '../../hooks/useUnprocessedFileHandler';
import { useTranslation } from 'react-i18next';

function FileIsReadyPopUp() {
    // const [selectedArticles, setSelectedArticles] = useRecoilState(selectedArticlesState);
    const setSelectedArticles = useSetRecoilState(selectedArticlesState);
    const setCurrentOpenArticleId = useSetRecoilState(currentOpenedArticleIdState);
    // const [currentOpenArticleId, setCurrentOpenArticleId] = useRecoilState(currentOpenedArticleIdState);
    const [popUpShown, setPopUpShown] = useRecoilState(popUpShowStatus); 
    const [uploadedFile, setUploadedFile] = useRecoilState(uploadingFileFromRecoil);
    const { hasFileProcessedSuccessfully } = useUnprocessedFileHandler();
    const { t } = useTranslation();

    const hasUploadedFileUploadedSuccessfully = uploadedFile && hasFileProcessedSuccessfully(uploadedFile);


    useEffect(() => {
        if(hasUploadedFileUploadedSuccessfully) {
            setPopUpShown(true);
        }
    }, [hasUploadedFileUploadedSuccessfully])

    const readArticle = () => {
        setSelectedArticles([uploadedFile]);
        setCurrentOpenArticleId(uploadedFile.id);
        closePopUp();
    }

    const closePopUp = () => {
        setPopUpShown(false);
        setUploadedFile(null);
    }; 

    return (
            <>
            { popUpShown && 
                <SlideFade in offsetY='20px' offsetX='-100%'>
                <Box position={'absolute'} zIndex={100} bottom={'20px'} left={'20px'}>
                    <Card border={'1px solid black'} w={'300px'}>
                        <CardBody bg={'yellow.400'} borderRadius={'6px'}>
                            <Flex flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                                <Heading w={'194px'} className='text-ellipsis' size={'sx'} mr={2}>{uploadedFile.name}</Heading>
                                is ready
                            </Flex>
                            
                        </CardBody>
                        <CardFooter>
                            <Flex w={'full'} flexDir='row' justifyContent='space-between'>
                                <Box>
                                    <Button 
                                        size={'sm'} 
                                        mr={2} 
                                        colorScheme="yellow"
                                        border={'1px solid black'}
                                        onClick={readArticle}>
                                            {t('start')}
                                    </Button>
                                </Box>
                                <Box>
                                    <Button size={'sm'} onClick={closePopUp}>{t('read-later')}</Button>
                                </Box>
                            </Flex>
                        </CardFooter>
                    </Card>
                </Box>
                </SlideFade>
            }
        </>
    );
}

export default FileIsReadyPopUp;