import { Box, Card, CardBody, Heading, Image, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { videos_en, videos_cn, VideoType } from './video.utils';
import { useSetRecoilState } from 'recoil';
import { globalVideoPlayerOpenStatus, videoResource } from '../../atoms/rootAtom';

const VideoCardsWrapper = () => {
    const { i18n, t } = useTranslation();
    const [videos, setVideos] = useState<VideoType[]>(videos_en);
    const setVideo = useSetRecoilState<VideoType>(videoResource);
    const showVideo = useSetRecoilState<boolean>(globalVideoPlayerOpenStatus);


    useEffect(() => {
        if (i18n.language == 'en') {
            setVideos(videos_en);
        } else {
            setVideos(videos_cn);
        }
    }, [i18n.language])

    const openVideoPlayer = (video: VideoType) => {
        showVideo(true);
        setVideo(video);
    }

    return (
        <Wrap mb={10}>
            {videos.map((video) => (
                <WrapItem key={video.title}>
                    <Card w={'100%'} maxW={'500px'} borderRadius={'10px'} boxShadow={'5px 5px 10px #ccc'} mb={10} mr={10} cursor={'pointer'} onClick={() => openVideoPlayer(video)}>
                        <CardBody pt={0} pl={0} pr={0}>
                            <Box className="intro-card-bg" p={8} borderRadius={'10px 10px 0 0'}>
                                <Image
                                    src={`/${video.title}.jpg`}
                                    alt={video.title}
                                    borderRadius='lg'
                                />
                            </Box>
                            <Stack mt='6' spacing='3' px={8} py={2}>
                                <Heading size='md'>{t(video.title)}</Heading>
                                {/* <Text>
                                Description for the Highlights For Related Context.
                            </Text> */}
                            </Stack>
                        </CardBody>
                    </Card>
                </WrapItem>
            ))}
        </Wrap>
    );
}

export default VideoCardsWrapper;