import { FileDetails } from "./FileDetails";

export interface FileProcessingResponse {
	[key: string]: FileProcessingStatus;
}

export interface FileProcessingStatus {
	exists: boolean;
	processingStatus: ProcessingStatus;
	percentage: number;
	name: string;
	file?: FileDetails;
}

export enum ProcessingStatus {
	PENDING = "PENDING",
	PROCESSED = "PROCESSED",
	RETRY = "RETRY",
	FAILED = "FAILED"
}