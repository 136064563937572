import { Box, IconButton, Container, Flex, VStack, Heading, Text, UnorderedList, ListItem, Tag, Link as ChakraLink } from "@chakra-ui/react";
import { BiLeftArrow } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";


const TermsOfUse = () => {
    return (
        <Container maxW='container.xl' pt={12}>
            <VStack spacing={4} align="stretch" overflow={'auto'} h={'90vh'}>
                <Flex flexDir={'row'} alignItems={'center'}>
                    <Box>
                        <ChakraLink as={RouterLink} to='/'>
                            <IconButton aria-label='go back to home page' variant={'ghost'} icon={<BiLeftArrow />} />
                        </ChakraLink>
                    </Box>
                    <Heading as="h2" size="xl" textAlign="left" my="4">
                            Terms of Use
                        </Heading>
                </Flex>
                <Tag maxW={'200px'} color={'gray.500'}>Last Updated: July 21, 2024</Tag>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    1. Acceptance of Terms
                </Heading>
                <Text fontSize="lg">
                    By accessing and using the services provided by Doenba.ai (“Service”), you acknowledge that you have read, understood, and agree to be bound by these Terms of Use (“Terms”). If you do not agree to these Terms, do not use the Service.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    2. Service Description
                </Heading>
                <Text fontSize="lg">
                    The Service uses artificial intelligence to help you comprehend and engage with written materials. The Service analyzes texts submitted by you and offers insights and explanations to enhance learning. Doenba.ai does not share the materials you submit with other users. The generated summaries and responses to your inquiries are for personal use only and not to be used for commercial purposes unless you have obtained written permission from Doenba.ai.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    3. User Responsibilities
                </Heading>
                <Text fontSize="lg">
                    You agree not to submit any content that is illegal, offensive, or infringes on the rights of others. To ensure compliance with copyright laws, you are responsible for the materials you submit to Doenba.ai. You are responsible for maintaining the confidentiality of your account information.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    4. Intellectual Property
                </Heading>
                <Text fontSize="lg">
                    The AI-generated insights and explanations is owned by Doenba.ai, and you are granted a non-exclusive license to use it for personal purposes. You may not reproduce, distribute, or create derivative works from the content provided by the Service unless you have obtained written permission from Doenba.ai.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    5. Prohibited Uses
                </Heading>
                <UnorderedList spacing={3} fontSize="lg">
                    <ListItem>Use the Service for any illegal purpose.</ListItem>
                    <ListItem>Attempt to gain unauthorized access to the Service or its related systems or networks.</ListItem>
                    <ListItem>Use the Service to create content that is defamatory, obscene, or otherwise objectionable.</ListItem>
                </UnorderedList>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    6. Disclaimer of Warranties
                </Heading>
                <Text fontSize="lg">
                    The Service is provided “as is” and “as available” without any warranties of any kind, express or implied, including the accuracy or reliability of generated content.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    7. Limitation of Liability
                </Heading>
                <Text fontSize="lg">
                    Doenba.ai will not be liable for any indirect, incidental, special, consequential, or punitive damages or losses resulting from your use of the Service.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    8. Changes to Terms
                </Heading>
                <Text fontSize="lg">
                    Doenba.ai reserves the right to modify these Terms at any time. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    9. Governing Law
                </Heading>
                <Text fontSize="lg">
                    These Terms of Use shall be governed by the laws of the jurisdiction in which the service provider is located.
                </Text>
                <Heading as="h4" size="md" textAlign="left" my="4">
                    10. Contact Information
                </Heading>
                <Text fontSize="lg">
                    For any questions or concerns regarding these Terms of Use, please contact us at info@doenba.ai.
                </Text>
            </VStack>
        </Container>
    );
};

export default TermsOfUse;

