export interface VideoType {
    title: string,
    source: string
}

export const videos_en: VideoType[] = [
    { title: 'video-title-rqa', source: 'https://public-librum-files.s3.amazonaws.com/video_demo1_RQA_en.mp4' },
    { title: 'video-title-inspiration', source: 'https://public-librum-files.s3.amazonaws.com/video_demo2_inspiration_en.mp4' },
    { title: 'video-title-tables-formulas', source: 'https://public-librum-files.s3.amazonaws.com/video_demo3_tables_formulas_en.mp4' },
    { title: 'video-title-organization', source: 'https://public-librum-files.s3.amazonaws.com/video_demo4_organization_en.mp4' },
    { title: 'video-title-undergrad-math_demo', source: 'https://public-librum-files.s3.amazonaws.com/video_demo5_undergrad_math_demo_en.mp4'},
];

export const videos_cn: VideoType[] = [
    { title: 'video-title-rqa', source: 'https://public-librum-files.s3.amazonaws.com/video_demo1_RQA_ch.mp4' },
    { title: 'video-title-inspiration', source: 'https://public-librum-files.s3.amazonaws.com/video_demo2_inspiration_ch.mp4' },
    { title: 'video-title-tables-formulas', source: 'https://public-librum-files.s3.amazonaws.com/video_demo3_tables_formulas_ch.mp4' },
    { title: 'video-title-organization', source: 'https://public-librum-files.s3.amazonaws.com/video_demo4_organization_ch.mp4' },
    { title: 'video-title-undergrad-math_demo', source: 'https://public-librum-files.s3.amazonaws.com/video_demo5_undergrad_math_demo_cn.mp4'},
];