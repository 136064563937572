import { MdMenuBook, MdOutlineQuestionAnswer } from "react-icons/md";
import { ArticleFeature } from "../types/ArticleFeature";
import { BiHighlight } from "react-icons/bi";
import { FaRegChartBar, FaTable } from "react-icons/fa";
import { TbMathFunction } from "react-icons/tb";
import { Box, BoxProps, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
	features: ArticleFeature[];
	showTooltip?: boolean;
}

const FeatureIcons = ({ features, showTooltip, ...containerProps}: Props) => {
	const { t } = useTranslation();

	const supportsQaHighlight = features.includes(ArticleFeature.QAHighlight);
	const supportsTopicHighlight = features.includes(ArticleFeature.TopicHighlight);
	
	const getHighlightText = () => {
		if (supportsQaHighlight && supportsTopicHighlight)
			return t('supports-both-highlights');
		if (supportsQaHighlight)
			return t('supports-topic-highlight');
		if (supportsTopicHighlight)
			return t('supports-task-rqa-highlight');

		return "";
	}

	return (
		<Box display="flex" gap={1} {...containerProps}>
			{features.includes(ArticleFeature.QA) &&
				<TooltipIcon text={t('supports-task-rqa')} icon={<MdOutlineQuestionAnswer color="gray" />} showTooltip={showTooltip} />
			}
			{(supportsQaHighlight || supportsTopicHighlight) &&
				<TooltipIcon text={getHighlightText()} icon={<BiHighlight color="gray"/>} showTooltip={showTooltip} />				
			}
			{features.includes(ArticleFeature.Summary) &&
				<TooltipIcon text={t('supports-custom-summary')} icon={<MdMenuBook color="gray"/>} showTooltip={showTooltip} />
			}
			{features.includes(ArticleFeature.Figure) &&
				<TooltipIcon text={t('supports-figures')} icon={<FaRegChartBar color="gray"/>} showTooltip={showTooltip} />
			}
			{features.includes(ArticleFeature.Table) &&
				<TooltipIcon text={t('supports-tables')} icon={<FaTable color="gray"/>} showTooltip={showTooltip} />
			}
			{features.includes(ArticleFeature.Equation) &&
				<TooltipIcon text={t('supports-equations')} icon={<TbMathFunction color="gray"/>} showTooltip={showTooltip} />
			}
		</Box>
	);
};

interface TooltipIconProps {
	text: string;
	icon: React.ReactNode;
	showTooltip?: boolean;
}

const TooltipIcon = ({ text, icon, showTooltip = true }: TooltipIconProps) => {
	return (
		<Tooltip 
			label={text}
			isDisabled={!showTooltip}
			hasArrow
			placement="top"
		>
			<span>
				{icon}
			</span>
		</Tooltip>
	)
}

export default FeatureIcons;
