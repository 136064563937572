import { useState, useEffect, useCallback } from 'react';
import { UserDataService } from '../services/userDataService';

export const useNote = () => {
    const [note, setNote] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        const fetchNote = async () => {
            try {
                setIsLoading(true);
                const response = await UserDataService.getUserData();
                setNote(response.note);
            } catch (error) {
                console.error("Failed to fetch note", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchNote();
    }, []);

    const saveNote = useCallback((newNote) => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(async () => {
            try {
                await UserDataService.updateUserData(newNote);
                setIsDirty(false);
            } catch (error) {
                console.error("Failed to save note", error);
            }
        }, 750);
        setTimer(newTimer);
    }, [timer]);

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return { note, setNote, isDirty, setIsDirty, isLoading, saveNote };
};