import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Directory } from "../../types/Directory";
import { FileDirectoryService } from "../../services";
import { useState } from "react";
import { ClientError } from "../../utils/clientError";

interface Props {
	isOpen: boolean;
	onClose: () => void;
	currentDirectoryId: number | null;
	onDirectoryCreate: (directory: Directory) => void;
}

const CreateDirectoryModal = ({ 
	isOpen,
	onClose,
	currentDirectoryId,
	onDirectoryCreate 
}: Props) => {
	const [isCreating, setIsCreating] = useState<boolean>(false);
	const [directoryName, setDirectoryName] = useState<string>("");

	const handleCreateDirectory = async () => {
		if (!directoryName || isCreating) return;

		setIsCreating(true);

		try {
			const newDirectory = await FileDirectoryService.createDirectory({
				parentId: currentDirectoryId,
				name: directoryName,
			});

			setDirectoryName("");
			onClose();
			onDirectoryCreate(newDirectory);
		} catch (e) {
			new ClientError(e).toast();
		} finally {
			setIsCreating(false);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			handleCreateDirectory();
		}
	}
	
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent onKeyDown={handleKeyDown}>
				<ModalHeader>Create Folder</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Input
						placeholder="Folder Name"
						value={directoryName}
						onChange={(e) => setDirectoryName(e.target.value)}
						autoFocus
					/>
				</ModalBody>

				<ModalFooter>
					<Button mr={3} onClick={onClose}>Close</Button>
					<Button
						colorScheme="yellow" 
						onClick={handleCreateDirectory}
						isLoading={isCreating}
					>
						Create
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CreateDirectoryModal;
