import { Box, Button, Flex, IconButton, Input, InputGroup, Text, InputLeftElement, InputRightElement, List, ListIcon, ListItem, Menu, Stack, Textarea, Tooltip } from '@chakra-ui/react';
import MiniNav from '../NavBar/MiniNav';
import { DeleteIcon, ChatIcon, CloseIcon, ViewIcon } from '@chakra-ui/icons';
import { BiCommentAdd, BiMessageAltDetail } from 'react-icons/bi';
import { FaPaperPlane, FaStop } from 'react-icons/fa';
import { MdOutlineThumbUp, MdOutlineThumbDown, MdKeyboardVoice } from 'react-icons/md';
import { formatDate } from '../../utils/dateTimeConversion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Result, Search, SearchConversationHistory } from '../../types/Search';
import { SearchService } from '../../services/searchService.ts';
import { ClientError } from '../../utils/clientError.ts';
import { searchResultProcessingState } from '../../atoms/rootAtom.ts';
import { useSetRecoilState } from 'recoil';
import TypingIndicator from '../TypingIndicator.tsx';

interface Props {
    setResults: React.Dispatch<React.SetStateAction<Result[]>>;
    setDuration: React.Dispatch<React.SetStateAction<number>>;
}

const SearchConversationWindow = ({ setResults, setDuration }: Props) => {
    const [searches, setSearches] = useState<Search[]>([]);
    const [selectedSearchId, setSelectedSearchId] = useState<string>('');
    const [searchConversations, setSearchConversations] = useState<SearchConversationHistory[]>([])
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [isResponding, setIsResponding] = useState<boolean>(false);
    const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const recognition = useRef<any | null>(null);
    const [newMessage, setNewMessage] = useState<string>('');
    const chatWindowRef = useRef<HTMLTextAreaElement | null>(null);
    const [selectedHistory, setSelectedHistory] = useState<SearchConversationHistory | null>(null);
    const setIsSearchingStatus = useSetRecoilState(searchResultProcessingState);

    const startTime = useRef<number>();
    const endTime = useRef<number>();

    const startTimer = useCallback(() => {
        startTime.current = new Date().getTime();
    }, [])

    // Function to stop the timer and calculate the duration
    const stopTimer = useCallback(() => {
        endTime.current = new Date().getTime();

        // Calculate the difference in milliseconds
        const timeDiff = endTime.current - startTime.current; // timeDiff will be in ms
        // Convert milliseconds to seconds (1 second = 1000 milliseconds)
        const seconds = timeDiff / 1000;
        return seconds;
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [searches]);

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [searchConversations]);

    const addNewSearch = async () => {
        try {
            const search = await SearchService.initSearch();
            const newSearches = [search, ...searches];
            setSearches(newSearches);
            selectSearch(search.id);
        } catch (error) {
            console.log(error);
        }
    }

    const selectSearch = async (id: string) => {
        setIsSearchingStatus(true);
        setSelectedSearchId(id);

        const history = await SearchService.getSearchInfo(id);
        setConversationHistory(history);
        setSearchResults(history);
    }

    const setSearchResults = useCallback(async (history: SearchConversationHistory[], duration?: number) => {
        const historyWithResults = history
            .filter((item) => item.results?.length > 0)
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        if (historyWithResults.length > 0) {
            if (duration) {
                setDuration(duration);
            } else {
                setDuration(null);
            }

            setSelectedHistory(historyWithResults[0]);
        }
    }, [setDuration]);

    useEffect(() => {
        if (selectedHistory) {
            selectedHistory.results.sort((a, b) => a.order - b.order);
            setResults(selectedHistory.results);
        }
    }, [selectedHistory, setResults]);

    const setConversationHistory = useCallback((history: SearchConversationHistory[]) => {
        setSearchConversations(history.sort(compareConversationHistory));
        setIsSearchingStatus(false);
    }, [])

    function compareConversationHistory(a: SearchConversationHistory, b: SearchConversationHistory) {
        if (a.createdAt < b.createdAt) {
            return -1;
        }
        if (a.createdAt > b.createdAt) {
            return 1;
        }
        // a must be equal to b
        if (a.sender === 'user' && b.sender === 'bot') {
            return -1;
        }
    }

    const deleteSelectedSearch = async () => {
        try {
            await SearchService.deleteSearch(selectedSearchId);
            const newSearches = searches.filter((search) => search.id !== selectedSearchId);
            setSearches(newSearches);

            if (newSearches.length > 0) {
                selectSearch(newSearches[0].id);
            } else {
                setSelectedSearchId('');
                setConversationHistory([]);
            }
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        setIsResponding(true);
        setNewMessage('');

        let targetSearchId = selectedSearchId;
        if (selectedSearchId === '') {
            const search = await SearchService.initSearch();
            const newSearches = [search, ...searches];
            setSearches(newSearches);
            selectSearch(search.id);
            targetSearchId = search.id;
        }

        try {
            const tempMessage: SearchConversationHistory = {
                id: 'tempMessage',
                sender: 'user',
                message: newMessage,
                createdAt: new Date().toString(),
                updatedAt: new Date().toString(),
            };
            setConversationHistory([...searchConversations, tempMessage]);

            startTimer();
            const history = await SearchService.addToSearch(targetSearchId, newMessage);
            const duration = stopTimer();

            const newHistory = [...searchConversations, ...history];

            setConversationHistory(newHistory);
            setSearchResults(newHistory, duration);
        } catch (error) {
            new ClientError(error).toast();
        } finally {
            setIsResponding(false);
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && event.shiftKey === false) {
            event.preventDefault();
            handleSendMessage();
            setIsTyping(false);
            if (chatWindowRef.current) {
                chatWindowRef.current.blur();
            }
        }
    };

    const startListening = () => {
        setIsSpeaking(true);
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
            recognition.current = new ((window as any).SpeechRecognition || (window as any).webkitSpeechRecognition)();
            recognition.current.onresult = (event) => {
                const result = event.results[0][0].transcript;
                setNewMessage(result);
                handleSendMessage();
                setIsSpeaking(false);
            };
            recognition.current.start();
        } else {
            alert('Speech recognition is not supported in your browser.');
            setIsSpeaking(false);
        }
    };

    const stopListening = () => {
        if (recognition.current) {
            recognition.current.stop();
            recognition.current = null;
        }
        setIsSpeaking(false);
    };

    useEffect(() => {
        async function getSearches() {
            try {
                const result = await SearchService.getSearches();
                setSearches(result);
            } catch (error) {
                console.log(error);
            }
        }

        getSearches();
    }, []);

    return (
        <Box height='100vh' width='100%'>
            <MiniNav />
            <Flex flexDir="row">
                <Box w='220px' h={'100%'}>
                    <Flex flexDir={'column'}>
                        <Button m={'2'} size={'sm'} leftIcon={<BiCommentAdd />} onClick={addNewSearch} mb={'3'} border={'1px solid black'}>New Search</Button>
                        <List h={'700px'} overflow={'auto'}>
                            {searches.map((search: Search) => (
                                <ListItem
                                    key={search.id}
                                    p='2'
                                    cursor={'pointer'}
                                    onClick={() => selectSearch(search.id)}
                                    bgColor={selectedSearchId === search.id ? 'gray.100' : 'initial'}
                                >
                                    <Flex direction='row' alignItems={'flex-start'}>
                                        <ListIcon mt='1' as={BiMessageAltDetail} color='gray.500' />
                                        <Flex flexDir={'column'}>
                                            <Text fontSize='sm' overflow='ellipse' fontWeight={selectedSearchId === search.id ? 'bold' : 'normal'}>{search.title}</Text >
                                            <Text fontSize='xs' overflow='ellipse' color={'gray.400'}>{formatDate(search.createdAt)}</Text >
                                        </Flex>
                                    </Flex>
                                </ListItem>
                            ))}
                        </List>
                    </Flex>
                </Box>
                <Box w={'100%'} overflow={'auto'} h={'82vh'}>
                    <Flex flexDir={'column'} w={'100%'} h="100%">
                        <Flex flexDir={'row'} justifyContent={'space-between'} h={'50px'} p={'2'} >
                            <IconButton size='xs' aria-label='Delete Search' onClick={deleteSelectedSearch} icon={<DeleteIcon />} />
                            <InputGroup size={'xs'} mx='1'>
                                <InputLeftElement pointerEvents='none'>
                                    <ChatIcon color='gray.300' />
                                </InputLeftElement>
                                <Input placeholder={selectedSearchId} />
                            </InputGroup>
                            <IconButton size='xs' aria-label='Delete Search' icon={<CloseIcon />} />
                        </Flex>
                        <Box flex={'1 auto'} w={'100%'} mt='5' overflow={'scroll'}>
                            <Box overflow={'auto'}>
                                <Stack spacing={3} paddingLeft={3}>
                                    {searchConversations.map((searchConversation, index) => (
                                        <Box key={index} maxW="70%" alignSelf={searchConversation.sender === 'user' ? 'flex-end' : 'flex-start'}>
                                            <Flex flexDir={'row'} justifyContent={searchConversation.sender === 'user' ? 'flex-end' : 'flex-start'}>
                                                <Menu isLazy>
                                                    <Flex flexDirection="column">
                                                        <Box borderRadius="lg"
                                                            pt='4'
                                                            px='3'
                                                            pb='2'
                                                            borderTopRightRadius={searchConversation.sender === 'user' ? '0px' : 'lg'}
                                                            borderBottomLeftRadius={searchConversation.sender !== 'user' ? '0px' : 'lg'}
                                                            bg={searchConversation.sender === 'user' ? 'black' : 'gray.200'}
                                                            color={searchConversation.sender === 'user' ? 'white' : 'black'}>
                                                            <Box pb='3'
                                                                fontSize={'14px'}
                                                                textAlign={'left'}
                                                            >
                                                                {searchConversation.message}
                                                            </Box>
                                                            <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                {searchConversation.sender !== 'user' &&
                                                                    <Box mr='5'>
                                                                        <IconButton size={'xs'} variant='outline' onClick={() => { }} aria-label='thumbup' icon={<MdOutlineThumbUp />} />
                                                                        <IconButton size={'xs'} variant='outline' onClick={() => { }} aria-label='thumbdown in' icon={<MdOutlineThumbDown />} />
                                                                    </Box>
                                                                }
                                                                <Box>
                                                                    <Text textAlign={'right'} fontSize='xs' color={searchConversation.sender === 'user' ? 'gray.400' : 'blackAlpha.600'}>{formatDate(searchConversation.createdAt)}</Text>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                        {searchConversation.sender === 'bot' && searchConversation.results.length > 0 &&
                                                            <Button
                                                                leftIcon={<ViewIcon />}
                                                                isDisabled={selectedHistory.id === searchConversation.id}
                                                                onClick={() => setSelectedHistory(searchConversation)}
                                                            >
                                                                {selectedHistory.id === searchConversation.id ? 'Showing' : 'View'} {searchConversation.results.length} Result{searchConversation.results.length > 1 ? 's' : ''}
                                                            </Button>
                                                        }
                                                    </Flex>
                                                </Menu>
                                            </Flex>
                                        </Box>
                                    ))}
                                    <Box ref={messagesEndRef}></Box>
                                </Stack>
                            </Box>
                        </Box>
                        <Box mt={4} h={'200px'} position='relative'>
                            <Box position='absolute' pt={1} top={-5} display="flex" flexDir="column" justifyContent="flex-start">
                                {(isTyping || isResponding) &&
                                    <TypingIndicator text={isResponding ? 'Task Search is responding' : 'You are typing'} />
                                }
                            </Box>
                            <InputGroup w={'100%'} flexDir={'row'} marginTop={1}>
                                <Box flex='1 auto'>
                                    <Textarea
                                        ref={chatWindowRef}
                                        placeholder="Type your search..."
                                        value={newMessage}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        size='sm'
                                        resize={'vertical'}
                                        w={'100%'}
                                        h={'165px'}
                                        onFocus={() => setIsTyping(true)}
                                        onBlur={() => setIsTyping(false)}
                                    />
                                </Box>
                                <Box w={'40px'} h={'100%'} ml={2}>
                                    <InputRightElement h={'100%'}>
                                        <Flex flexDir={'column'} h={'100%'}>
                                            <Tooltip label='Ctrl + Enter'>
                                                <IconButton
                                                    mb={'1'}
                                                    aria-label="Send"
                                                    colorScheme='yellow'
                                                    border={'1px solid black'}
                                                    icon={<FaPaperPlane />}
                                                    onClick={handleSendMessage}
                                                />
                                            </Tooltip>
                                            {!isSpeaking &&
                                                <Tooltip label='Voice Input'>
                                                    <IconButton
                                                        aria-label="Send"
                                                        icon={<MdKeyboardVoice />}
                                                        onClick={startListening}
                                                    />
                                                </Tooltip>
                                            }
                                            {isSpeaking &&
                                                <Tooltip label='Voice Input'>
                                                    <IconButton
                                                        aria-label="Send"
                                                        icon={<FaStop />}
                                                        onClick={stopListening}
                                                    />
                                                </Tooltip>
                                            }
                                        </Flex>
                                    </InputRightElement>
                                </Box>
                            </InputGroup>
                        </Box>
                    </Flex>
                </Box>
            </Flex >
        </Box >
    );
}

export default SearchConversationWindow;