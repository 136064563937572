import { Box, Button, Center, CircularProgress, Flex, IconButton, Progress, Text, Tooltip } from "@chakra-ui/react";
import { FileDetails } from "../types/FileDetails";
import { useEffect, useState } from "react";
import useUnprocessedFileHandler from "../hooks/useUnprocessedFileHandler";
import { ProcessingStatus } from "../types/FileProcessingStatus";
import { ArticleProcessingService } from "../services/articleProcessingService";
import { ClientError } from "../utils/clientError";
import { useTranslation } from "react-i18next";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { useSetRecoilState } from "recoil";
import { uploadingFile } from "../atoms/rootAtom";

interface Props {
	file: FileDetails;
	isQuickFileUploader?: boolean;
}
const ProcessingIndicator = ({ file: initFile, isQuickFileUploader }: Props) => {
  const { t } = useTranslation();
	const { hasFileFailedProcessing, getProcessingStatus, canFileBeRetried, addNeededFilesToPoller } = useUnprocessedFileHandler();
	const [file, setFile] = useState(initFile);
	const isFailure = hasFileFailedProcessing(file);
	const canBeRetried = canFileBeRetried(file);
	const processingStatus = getProcessingStatus(file.id);
	const color = !isFailure ? "green.100" : "orange.200";
	const [shouldHideProgressBarAfterCompletion, setShouldHideProgressBarAfterCompletion] = useState(false); 
	const setUploadingFile = useSetRecoilState(uploadingFile);
  const [displayedPercentage, setDisplayedPercentage] = useState(processingStatus?.percentage || 0);

	useEffect(() => {
		setFile(initFile);
	}, [initFile]);

	// Reset displayed percentage when file changes
	useEffect(() => {
			setDisplayedPercentage(0);
	}, [file]);

	useEffect(() => {
		let interval;
		if (processingStatus) {
				if (displayedPercentage < processingStatus.percentage) {
					// Rapid catch-up if behind actual percentage
					setDisplayedPercentage(processingStatus.percentage);
				} else if (displayedPercentage < processingStatus.percentage + 5) {
					// Slow motion effect within 5% beyond actual percentage
					interval = setInterval(() => {
						setDisplayedPercentage((prev) => {
							const nextValue = Math.min(prev + 0.1, processingStatus.percentage + 5);
							if (nextValue >= processingStatus.percentage + 5) {
								clearInterval(interval);
							}
							return nextValue;
						});
				}, 1000);
			}
		}
		return () => clearInterval(interval);
  }, [processingStatus, displayedPercentage]);

	useEffect(() => {
		if (processingStatus && processingStatus.processingStatus === ProcessingStatus.PROCESSED) {
			setTimeout(() => {
				setShouldHideProgressBarAfterCompletion(true);
			}, 1000);
		}
	}, [processingStatus]);

	const retryArticleProcessing = async () => {
		try {
			const updatedFile = await ArticleProcessingService.requeueArticleForProcessing(file.id);
			addNeededFilesToPoller(updatedFile);
			setFile(updatedFile);
		} catch (error) {
			console.log(error);
			new ClientError(error).toast();
		}
	}

	const clearUploadingFile = async () => {
		setUploadingFile(null);
	}

	if (
		file.processingStatus === ProcessingStatus.PROCESSED ||	// Purposefully not using hasFileProcessedSuccessfully here to allow progress bar to reach 100% on UI
		(!processingStatus && !isFailure) ||
		shouldHideProgressBarAfterCompletion
	) {
		return null;
	}

	return !isFailure ? (
		<Flex
			position="absolute"
			borderRadius={50}
			top={'-5px'}
			left={0}
			height={'35px'}
			width="100%"
			alignItems={"center"}
			flexDir={'row'}
			alignContent={'center'}
			justifyContent={'center'}
		>
			{!isFailure && (
				<Tooltip placement="top" label={t('processing-indicator-tooltip-label')}>
					<Flex width="100%" alignItems="center">
						<Progress
							colorScheme="yellow"
							value={displayedPercentage} 
							hasStripe
							// value={processingStatus.percentage} 
							flex={1} 
							h={'35px'}
							borderRadius={isQuickFileUploader ? '6px' : '6px 6px 0 0'}
							bgColor={"gray.300"}
							sx={{
								'& > div': {
									transition: 'width 1s ease-out',
								},
							}}
						/>
						<Center w={'full'} height='35px' pos={'absolute'} top={0} left={0}>
							<Box><CircularProgress size={'20px'} isIndeterminate color='gray.300' mr={1} /></Box>
							<Text fontSize='sm'>{t('in-progress-indicator-label')}</Text>
						</Center>
					</Flex>
				</Tooltip>
			)}
		</Flex>
	) : (
		<Flex
			flexDir="row"
			position="absolute"
			backgroundColor={color}
			paddingY={1}
			paddingX={2}
			borderRadius={50}
			top={isQuickFileUploader ? 0 : 3}
			right={isQuickFileUploader ?  0: 3}
			alignItems={"center"}
			justifyContent={"space-between"}
			w={isQuickFileUploader ? '180px' : '160px'}
		>
			<Tooltip placement="top" label={t('processing-indicator-error')}>
				<Text fontSize={10} mr={isQuickFileUploader ? 2 : 0}>{t('failure-indicator-label')}</Text>
			</Tooltip>
			<Flex gap={1}>
				{canBeRetried &&
					<Button size={'xs'} colorScheme="red" border={'1px solid black'} onClick={retryArticleProcessing}>{t('retry-indicator-label')}</Button>
				}
				{isQuickFileUploader &&
					<IconButton
						aria-label="Close"
						icon={<SmallCloseIcon />}
						size="s"
						variant="unstyled"
						onClick={clearUploadingFile}
					/>
				}
			</Flex>
		</Flex>
	);

};

export default ProcessingIndicator;
