import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { fileProcessingSelector, filesSetter } from '../atoms/rootAtom';
import { FileService } from '../services';
import { ProcessingStatus } from '../types/FileProcessingStatus';
import useDirectories from './useDirectories';

const useProcessingPoller = () => {
	const toast = useToast();
	const updateFiles = useSetRecoilState(filesSetter);
	const [fileProcessing, setFileProcessing] = useRecoilState(fileProcessingSelector);
	const { updateFilesInDirectories } = useDirectories();

	useEffect(() => {
		if (fileProcessing && fileProcessing.size > 0) {
			const interval = setInterval(async () => {
				// Files only need to be checked if they are not already processed or failed
				const fileIdsToCheck = Array.from(fileProcessing.entries())
					.filter(([, status]) => !(status.processingStatus === ProcessingStatus.PROCESSED
																		|| status.processingStatus === ProcessingStatus.FAILED
																		|| status.processingStatus === ProcessingStatus.RETRY
																		|| !status.exists
																	))
					.map(([id]) => id);

				if (fileIdsToCheck.length === 0) {
					clearInterval(interval);
					return;
				}

				// Call the API to check the status of the files
				const response = await FileService.checkFilesProcessingStatus(fileIdsToCheck);

				const newFilesProcessing = new Map(fileProcessing);
				for (const id of fileIdsToCheck) {
					const status = response[id];
					if (status && status.processingStatus === ProcessingStatus.PROCESSED) {
						toast({
							title: "File Processed",
							description: `${status.name} has been fully processed.`,
							status: "success",
						});
					}

					if (status && status.processingStatus === ProcessingStatus.RETRY) {
						toast({
							title: "File Processing Failed",
							description: `${status.name} has failed to process. Please try again later.`,
							status: "error",
						});
					}

					if (status && status.processingStatus === ProcessingStatus.FAILED) {
						toast({
							title: "File Processing Failed",
							description: `${status.name} has failed to process. File is not supported.`,
							status: "error",
						});
					}

					newFilesProcessing.set(id, status);

					if (status.file && status.processingStatus === ProcessingStatus.PROCESSED) {
						updateFiles(status.file);
						updateFilesInDirectories(status.file);
					}
				}
				
				setFileProcessing(newFilesProcessing);
			}, 5000);

			return () => clearInterval(interval);
		}
	}, [fileProcessing, setFileProcessing, updateFilesInDirectories, toast, updateFiles]);
};

export default useProcessingPoller;