import React from 'react';
import { useDrop } from 'react-dnd';
import { Box } from '@chakra-ui/react';
import { UserFileDataService } from '../../services/userFileDataService';
import { ClientError } from '../../utils/clientError';
import { FileDirectoryService } from '../../services';

interface DroppableAreaProps {
    directoryId: string;
    children: React.ReactNode;
    removeFileFromCurrentDirectory: (id: string) => void;
    removeDirectoryFromCurrentDirectory: (id: number) => void;
}

const DroppableArea: React.FC<DroppableAreaProps> = ({ 
    directoryId,
    children,
    removeFileFromCurrentDirectory,
    removeDirectoryFromCurrentDirectory
}) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'BOX',
        drop: (item: { id: string }) => onDrop(item, directoryId),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const onDrop = (item: { id: string }, directoryId) => {
        const [type, id] = item.id.split('_');
        if (type === 'file') {
            FileOnDrop(id, directoryId)
        } else if (type === 'directory') {
            DirectoryOnDrop(parseInt(id), directoryId)
        }
    }

    const FileOnDrop = async (fileId: string, directoryId: number) => {
        try {
            await UserFileDataService.updateDirectory(
                fileId, 
                directoryId
            );
            removeFileFromCurrentDirectory(fileId);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    const DirectoryOnDrop = async (sourceDirectoryId: number, targetDirectoryId: string) => {
        try {
            const currentDirectoryInfo = await FileDirectoryService.getDirectoryContents(sourceDirectoryId);
            await FileDirectoryService.updateDirectory(
                sourceDirectoryId, 
                {
                    parentId: parseInt(targetDirectoryId),
                    name: currentDirectoryInfo.name
                }
            );
            removeDirectoryFromCurrentDirectory(sourceDirectoryId);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    return (
        <Box
            ref={drop}
            borderRadius={'5px'}
            backgroundColor={isOver ? 'yellow.700' : 'yellow.400'}
        >
            {children}
        </Box>
    );
};

export default DroppableArea;