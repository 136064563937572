import React, { useEffect, useState } from "react";
import DisabledFeatureTooltip from "../DisabledFeatureTooltip";
import {
	Box,
	Center,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	List,
	ListItem,
	Spinner,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import useHighlights, { HighlightType } from "../../hooks/useHighlights";
import { TopicSearchService } from "../../services/topicSearchService";
import { ClientError } from "../../utils/clientError";
import { useTranslation } from "react-i18next";

interface Props {
	fileId: string;
	isDisabled: boolean;
}

const TopicSearch = ({ fileId, isDisabled }: Props) => {
	const { t } = useTranslation();
	const [isPromptLoading, setPromptLoading] = useState<boolean>(false);
	const [topicSearchPrompt, setTopicSearchPrompt] = useState<string>("");
	const { highlightSentences } = useHighlights();
	const [history, setHistory] = useState<string[]>([]);
	const [showSearchHistory, setShowSearchHistory] = useState<boolean>(false);

	useEffect(() => {
		setTopicSearchPrompt("");
	}, [fileId]);

	useEffect(() => {
		async function getExistingTopicSearchHistory() {
			try {
				const existingHistories = await TopicSearchService.getTopicSearchHistory(fileId);
				setHistory(existingHistories);
			} catch (error) {
				new ClientError(error).toast();
			}
		}

		setHistory([]);
		getExistingTopicSearchHistory();
	}, [fileId]);

	const handleSearchbarOnKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			getTopicSearchResults();
		}
	};

	const getTopicSearchResults = async (overrideTopicSearchPrompt?: string) => {
		const topicSearchPromptToUse = overrideTopicSearchPrompt || topicSearchPrompt;

		if (fileId === "" || topicSearchPromptToUse === "") {
			return;
		}

		try {
			setPromptLoading(true);

			const response = await TopicSearchService.getTopicSearchResults(
				fileId,
				topicSearchPromptToUse
			);
			highlightSentences(response.highlight, HighlightType.TaskReading);

			const newHistory = [topicSearchPromptToUse, ...history];
			setHistory(newHistory.slice(0, 5));
		} catch (error) {
			new ClientError(error).toast();
		} finally {
			setPromptLoading(false);
		}
	};

	return (
		<DisabledFeatureTooltip isDisabled={!isDisabled}>
			<Flex
				flexDir={"column"}
				alignItems={"center"}
				w={"full"}
				maxW={"600px"}
				position="relative"
			>
				<InputGroup>
					{!isPromptLoading && (
						<>
							<InputLeftElement h={"30px"} w={"30px"} pointerEvents="none">
								<SearchIcon color="gray.300" />
							</InputLeftElement>
							<Input
								pl={8}
								mr={2}
								placeholder={t("oracle-reader-label")}
								value={topicSearchPrompt}
								size={"sm"}
								onChange={(e) => {
									setTopicSearchPrompt(e.target.value);
									setShowSearchHistory(e.target.value === "");
								}}
								onKeyDown={handleSearchbarOnKeydown}
								onFocus={() => setShowSearchHistory(true)}
								onBlur={() => setTimeout(() => setShowSearchHistory(false), 200)}
								isDisabled={isDisabled}
							/>
						</>
					)}
					{isPromptLoading && (
						<Center w={"full"}>
							<Spinner />
						</Center>
					)}
				</InputGroup>
				{showSearchHistory && (
					<Box
						position="absolute"
						top="100%"
						left={0}
						right={0}
						bg="white"
						boxShadow="md"
						zIndex={1}
						mt={1}
						borderRadius="md"
					>
						<List spacing={2}>
							{history.map((item, index) => (
								<ListItem
									key={index}
									onClick={() => {
										setShowSearchHistory(false);
										setTopicSearchPrompt(item);
										getTopicSearchResults(item);
									}}
									cursor="pointer"
									_hover={{ bg: "gray.100" }}
									p={2}
									borderRadius="md"
								>
									{item}
								</ListItem>
							))}
						</List>
					</Box>
				)}
			</Flex>
		</DisabledFeatureTooltip>
	);
};

export default TopicSearch;
