import { useRecoilValue } from "recoil";
import { Highlight } from "../types/Highlight";
import { currentOpenedArticleIdState } from "../atoms/rootAtom";

export enum HighlightType {
    TaskReading = "task-reading-highlighted",
    TaskQA = "task-qa-highlighted",
    Quotation = "quotation-highlighted",
}

function scrollToElementWithOffset(element, offset) {
    // Scroll the element into view
    element.scrollIntoView();
  
    // Get the element's position relative to the viewport
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
  
    // Adjust the scroll position with the desired offset
    const scrollToPosition = absoluteElementTop - offset;
  
    // Scroll to the adjusted position
    window.scrollTo({
      top: scrollToPosition,
    //   behavior: 'smooth' // Optional: for smooth scrolling
    });
  }

const useHighlights = () => {
    const currentOpenedArticleId = useRecoilValue(currentOpenedArticleIdState);

    const clearHighlights = (highlightType: HighlightType[] | HighlightType) => {
        if (Array.isArray(highlightType)) {
            highlightType.forEach((type) => {
                clearHighlights(type);
            });
            return;
        }

        const targetSentences = Array.from(document.querySelectorAll(`span.${highlightType}`));
        targetSentences.forEach((sentence) => {
            if (sentence instanceof HTMLElement) {
                sentence.classList.remove(highlightType);
            }
        });
    }

    const highlightSentences = (highlight: Highlight, highlightType: HighlightType, noScroll?: boolean) => {
        clearHighlights(highlightType);
        if (highlight) {
            if (currentOpenedArticleId in highlight) {
                highlight[currentOpenedArticleId].forEach(sentenceIndex => {
                    const targetSentences = Array.from(document.querySelectorAll(`span[s_index="${sentenceIndex}"]`));
                    targetSentences.forEach((targetSentence) => {
                        if (targetSentence instanceof HTMLElement) {
                            targetSentence.classList.add(highlightType);
                        } else {
                            console.error('"targetSentence" not found.');
                        }
                    });
                });

                if(highlight[currentOpenedArticleId].length > 0) {
                    const firstHighlightedSentence = document.querySelector(`span[s_index="${highlight[currentOpenedArticleId][0]}"]`) as HTMLElement;
                    if (firstHighlightedSentence) {
                        // firstHighlightedSentence?.scrollIntoView();
                        if(!noScroll) {
                            scrollToElementWithOffset(firstHighlightedSentence, 68);
                        } 
                    } else {
                        console.error('Cant find the auto sentence for scrolling.');
                    }
                }
            }
        }
    }

    return { clearHighlights, highlightSentences };
};

export default useHighlights;