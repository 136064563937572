import { Box, Text } from '@chakra-ui/react';

const TypingIndicator = ({ text }: { text: string}) => {
	return (
			<Box>
					<Text
							fontSize='xs'
							color={'blackAlpha.600'} 
							className='conversation-loading'
					>
							{text}
					</Text>
			</Box>
	);
};

export default TypingIndicator;