import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();
import * as Sentry from "@sentry/react";

interface Error {
	response?: {
		data: unknown;
	};
	message: string;
}

export class ClientError {
	error: Error;

	constructor(error: unknown) {
		this.error = error as Error;
		console.error(error);
	}

	message() {
		const res = this.error.response;
		if (!res) return this.error.message;

		return typeof res.data === "string" ? res.data : JSON.stringify(res.data);
	}

	toast(title?: string, timeout = 5000) {
		toast({
			title: title || "An error occured.",
			description: this.message(),
			duration: timeout,
			status: "error",
			isClosable: true,
		});

		// If a user is seeing an error, we should log it to Sentry
		Sentry.captureException(this.error);
	}
}
