import {
	Card,
	CardBody,
	Heading,
	Stack,
	Box,
	Divider,
	CardFooter,
	Button,
	Link,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Flex,
} from "@chakra-ui/react";
import { ViewIcon, DeleteIcon, DownloadIcon, HamburgerIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { FileService } from "../services/fileService";
import { FileDetails } from "../types/FileDetails";
import { dateToString } from "../services/dateTimeConversion";
import { ClientError } from "../utils/clientError";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentOpenedArticleIdState, pendingFileIdForCutting, selectedArticlesState } from "../atoms/rootAtom";
import ProcessingIndicator from "./ProcessingIndicator";
import { useEffect } from "react";
import useUnprocessedFileHandler from "../hooks/useUnprocessedFileHandler";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import FeatureIcons from "./FeatureIcons";
import useDirectories from "../hooks/useDirectories";
import { IoMdCut } from "react-icons/io";

interface Props {
	file: FileDetails;
	selectArticle?: () => void;
	onDeleteClick?: (id: string) => void;
	onMoveToParent?: () => void;
	hideAction?: boolean;
}

const FileCard = ({
	file,
	selectArticle,
	onDeleteClick,
	hideAction,
	onMoveToParent,
}: Props) => {
	const { t } = useTranslation();
	const { currentDirectory } = useDirectories();
	const { hasFileProcessedSuccessfully, addNeededFilesToPoller } = useUnprocessedFileHandler();
	const setCurrentOpenedArticleId = useSetRecoilState(currentOpenedArticleIdState);
	const setSelectedArticles = useSetRecoilState(selectedArticlesState);
	const navigate = useNavigate();
	const [fileOnCutting, setFileOnCutting] = useRecoilState(pendingFileIdForCutting);

	useEffect(() => {
		addNeededFilesToPoller(file);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file])

	async function downloadFile(id: string) {
		try {
			const response = await FileService.downloadFile(id);

			// force user to download file (since they clicked on link)
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement("a");
			link.download = file.name;
			link.href = url;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (e) {
			new ClientError(e).toast();
		}
	}

	const cardOnClick = () => {
		if (hideAction) {
			showFileInReader();
		}
	};

	const showFileInReader = () => {
		setCurrentOpenedArticleId(file.id);
		setSelectedArticles(() => [file]);
		navigate("/reader");
	};

	return (
		<Card
			width='100%'
			h="280px"
			// backgroundImage={"paper-file.jpg"}
			backgroundSize={"100%"}
			border={"1px solid none"}
			cursor={"pointer"}
			_hover={{ boxShadow: "5px 5px 5px #ccc", transition: "all 0.2s ease" }}
			onClick={cardOnClick}
			overflow={'hidden'}
			opacity={fileOnCutting.id == file.id ? 0.3 : 1}
		>
			<CardBody position="relative">
				<ProcessingIndicator file={file} />
				{hasFileProcessedSuccessfully(file) && file.features &&
					<FeatureIcons
						position="absolute"
						width="88%"
						features={file.features}
						justifyContent="flex-end"
					/>
				}
				<Stack mt="6" spacing="3">
					{/* <Heading size="md" h={'50px'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
							<Link onClick={showFileInReader}>
								{file.name}
							</Link>
						</Heading> */}

					<Heading size="md" h={'50px'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
						<Box
							as="div"
							display="-webkit-box"
							overflow="hidden"
							whiteSpace="normal"
							textOverflow="ellipsis"
							style={{
								WebkitLineClamp: '2',
								WebkitBoxOrient: 'vertical',
							}}
							h={'50px'}
						>
							<Link onClick={showFileInReader}>
								{file.name}
							</Link>
						</Box>
					</Heading>

					<Box>
						<Heading size="xs" color={"gray.500"}>
							{t('created-on-label')}: {dateToString(new Date(file.createdAt))}
						</Heading>
						<Heading size="xs" color={"gray.500"}>
							{t('updated-at-label')}: {dateToString(new Date(file.updatedAt))}
						</Heading>
					</Box>
				</Stack>
			</CardBody>
			<Divider color="gray.300" />
			{!hideAction && (
				<CardFooter>
					<Flex w='full' flexDir='row' alignItems='center' justifyContent='flex-end'>
						{hasFileProcessedSuccessfully(file) &&
							<Button
								size={"sm"}
								border={"1px solid black"}
								leftIcon={<IoChatboxEllipsesOutline />}
								mr={2}
								colorScheme="yellow"
								onClick={showFileInReader}
							>
								{t('start-to-chat-label')}
							</Button>
						}
						<Box>
							<Menu>
								<MenuButton
									as={IconButton}
									aria-label="Options"
									border={"1px solid black"}
									icon={<HamburgerIcon />}
									size={"sm"}
								/>
								<MenuList>
									{selectArticle && (
										<MenuItem
											icon={<ViewIcon />}
											onClick={() => selectArticle()}
										>
											{t('preview-label')}
										</MenuItem>
									)}
									<MenuItem
										icon={
											<DownloadIcon />
										}
										onClick={() => downloadFile(file.id)}
									>
										{t('download-label')}
									</MenuItem>
									<MenuItem
										icon={
											<IoMdCut />
										}
										onClick={() => setFileOnCutting({
											id: file.id,
											name: file.name
										})}
									>
										{t('cut-label')}
									</MenuItem>
									{onDeleteClick && (
										<MenuItem
											icon={
												<DeleteIcon color={"red"} />
											}
											onClick={() => onDeleteClick(file.id)}
										>
											{t('remove-label')}
										</MenuItem>
									)}
									{onMoveToParent && currentDirectory.id !== null && (
										<MenuItem
											icon={
												<ChevronLeftIcon color={"blue"} />
											}
											onClick={onMoveToParent}
										>
											Move folder to {currentDirectory?.parent?.name || 'My Library'}
										</MenuItem>
									)}

								</MenuList>
							</Menu>
						</Box>
					</Flex>
				</CardFooter>
			)}
		</Card>
	);
};

export default FileCard;
