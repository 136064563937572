import { Button } from "@chakra-ui/react";
import useAuthFunctions from "../../hooks/useAuthFunctions";
import { useTranslation } from "react-i18next";

const LogoutBtn = () => {
	const { handleLogout } = useAuthFunctions();
    const { t } = useTranslation();

	return (
		<Button onClick={handleLogout} colorScheme="red" size="sm">{t('logout-label')}</Button>
	);
};

export default LogoutBtn;