import { useRecoilState } from 'recoil';
import { fileProcessingSelector } from '../atoms/rootAtom';
import { ProcessingStatus } from '../types/FileProcessingStatus';

interface PartialFile {
	id: string;
	name?: string;
	processingStatus: ProcessingStatus;
}

const failedStates = [ProcessingStatus.FAILED, ProcessingStatus.RETRY];

/**
 * This hook is used to handle unprocessed files.
 * It simplifies the code for handling unprocessed files.
 * @returns An object containing functions to handle unprocessed files
 */
const useUnprocessedFileHandler = () => {
	const [fileProcessing, setFileProcessing] = useRecoilState(fileProcessingSelector);

	/**
	 * This function is used to add files to the poller if:
	 * 1. The file is not already being polled
	 * 2. The file is not already processed
	 * 3. The file processing has not failed
	 * @param files The file(s) to potentially add to the poller 
	 */
	const addNeededFilesToPoller = (files: PartialFile | PartialFile[]) => {
		if (!Array.isArray(files)) {
			files = [files];
		}

		const newFilesProcessing = new Map(fileProcessing);
		for (const file of files) {
			// If the file is already being polled and the status is going from RETRY to PENDING,
			// remove it from the poller so it can be updated with the new file.
			const isAlreadyInPoller = newFilesProcessing.has(file.id);
			const existingFile = newFilesProcessing.get(file.id);
			const isStatusGoingFromStoppedToProcessing = existingFile?.processingStatus === ProcessingStatus.RETRY && file.processingStatus === ProcessingStatus.PENDING;
			if (isAlreadyInPoller && isStatusGoingFromStoppedToProcessing) {
				newFilesProcessing.delete(file.id);
			}

			if (newFilesProcessing.has(file.id)
				|| file.processingStatus === ProcessingStatus.PROCESSED
				|| file.processingStatus === ProcessingStatus.RETRY
				|| file.processingStatus === ProcessingStatus.FAILED
			) {
				continue;
			}
			
			newFilesProcessing.set(file.id, {
				exists: true,
				processingStatus: file.processingStatus || ProcessingStatus.PENDING,
				percentage: 0,
				name: file.name || "",
			});
		}

		setFileProcessing((prev) => new Map([...prev, ...newFilesProcessing]));
	};

	/**
	 * This function is used to check if a file has been processed successfully.
	 * @param file The file to check if it has been processed successfully
	 * @returns `true` if the file has been processed successfully, `false` otherwise
	 */
	function hasFileProcessedSuccessfully(file: PartialFile) {
		const status = getProcessingStatus(file.id);
		return file.processingStatus === ProcessingStatus.PROCESSED || (status && status.processingStatus === ProcessingStatus.PROCESSED);
	}

	/**
	 * This function is used to check if a file's processing can be retried.
	 * @param file The file to check if it can be retried
	 * @returns `true` if the file can be retried, `false` otherwise
	 */
	function canFileBeRetried(file: PartialFile) {
		const status = getProcessingStatus(file.id);
		return file.processingStatus === ProcessingStatus.RETRY || (status && status.processingStatus === ProcessingStatus.RETRY);
	}

	/**
	 * This function is used to check if a file has failed processing.
	 * @param file The file to check if it has failed processing
	 * @returns `true` if the file has failed processing, `false` otherwise
	 */
	function hasFileFailedProcessing(file: PartialFile) {
		const status = getProcessingStatus(file.id);
		return failedStates.includes(file.processingStatus) || (status && failedStates.includes(status.processingStatus));
	}

	/**
	 * This function is used to get the processing status of a file.
	 * This should be used in rare cases where special handling is needed.
	 * @param id The id of the file to get the processing status of
	 * @returns The processing status of the file
	 */
	function getProcessingStatus(id: string) {
		return fileProcessing.get(id);
	}

	return { addNeededFilesToPoller, hasFileProcessedSuccessfully, canFileBeRetried, hasFileFailedProcessing, getProcessingStatus }
};

export default useUnprocessedFileHandler;