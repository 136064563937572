import axios from "axios";
import { FileDetails, UserFileDetails } from "../types/FileDetails";

import { BASE_URL } from ".";

export class UserFileDataService {
	public static async getDriveFiles(): Promise<FileDetails[]> {
		const response = await axios.get(BASE_URL + "/userFileData", {
			params: { isInDrive: true },
		});
		return response.data;
	}

	public static async getReadingListFiles(): Promise<FileDetails[]> {
		const response = await axios.get(BASE_URL + "/userFileData", {
			params: { isInReadingList: true },
		});
		return response.data;
	}

	public static async getFileDataForUser(fileIds: string[]): Promise<UserFileDetails[]> {
		const response = await axios.post(BASE_URL + "/userFileData", { fileIds });
		return response.data;
	}

	public static async toggleReadingList(id: string): Promise<UserFileDetails> {
		const response = await axios.put(BASE_URL + '/userFileData/reading-list', { id });
		return response.data;
	}

	public static async checkReadingListStatus(id: string): Promise<boolean> {
		try {
			const response = await axios.get(`${BASE_URL}/userFileData/reading-list/status`, { params: { id } });
			return response.data.isInReadingList;
		} catch (error) {
			console.error("Failed to check reading list status", error);
			throw error;
		}
	}

	public static async toggleIsInDrive(id: string): Promise<UserFileDetails> {
		const response = await axios.put(BASE_URL + '/userFileData/my-drive', { id });
		return response.data;
	}

	public static async updateDirectory(fileId: string, directoryId: number): Promise<UserFileDetails> {
		const response = await axios.put(BASE_URL + '/userFileData/update-directory', { fileId, directoryId });
		return response.data;
	}
}