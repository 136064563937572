import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { globalVideoPlayerOpenStatus, videoResource } from '../../atoms/rootAtom';
import { useTranslation } from 'react-i18next';

const VideoPlayerComponent = () => {
    const [openStatus, setOpenSatus] = useRecoilState(globalVideoPlayerOpenStatus);
    const [videoSource, setVideoSource] = useRecoilState(videoResource);
    const { t } = useTranslation();

    const videoPlayerOnClose = () => {
        setOpenSatus(false);
        setVideoSource({
            title: '',
            source: ''
        });
    }

    return (
        <>
            <Modal isOpen={openStatus} onClose={videoPlayerOnClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t(videoSource.title)}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <video
                            width="100%"
                            controls
                            autoPlay
                            loop
                            controlsList='nodownload'
                        >
                            <source src={videoSource.source} type="video/mp4" />
                            Sorry, your browser does not support embedded videos.
                        </video>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default VideoPlayerComponent;
