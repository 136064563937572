import { Box, Flex, IconButton, Image, Tooltip, Drawer, DrawerContent, DrawerOverlay, useBreakpointValue, Button } from "@chakra-ui/react";
import UserMenu from "./Components/UserMenu";
import LangSelect from "./LangSelect";
import { useAuth0 } from "@auth0/auth0-react";
import OperationWindowTabComponent from "../OperationWindowTabbar/OperationWindowTabComponent";
import { useState } from "react";
import { BsListColumns } from "react-icons/bs";
import ArticleList from "../ArticleList/ArticleList";
import { useLocation } from "react-router-dom";
import QuickFileUploader from "../QuickFileUploader/QuickFileUploader";
import { useTranslation } from "react-i18next";


const MiniNav = () => {
    const { t } = useTranslation();
    const { user, isAuthenticated } = useAuth0();
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const location = useLocation();
    const isCurrentPathMyDrive = location.pathname === "/my-library" || location.pathname === "/about";
    const isMobile = useBreakpointValue({ base: true, md: true, lg: true, xl: false });

    return (
        <Box>
            <Flex flexDir={'row'} w={'full'} alignItems="center" justifyContent={'space-between'}  h={'full'}>
                <Flex flexDir={'row'} alignItems={'center'}>
                    {isCurrentPathMyDrive &&
                        <Box>
                            <Image
                                w={'105px'}
                                objectFit='cover'
                                src='/doenba-logo.png'
                                alt='Doenba'
                            />
                        </Box>
                    }
                    <OperationWindowTabComponent isMobile = {isMobile}/>
                </Flex>
                { !isCurrentPathMyDrive && 
                    <Box flex={'1 auto'} mx={2} h={'35px'}>
                        <QuickFileUploader />
                    </Box>
                }
                <Flex flexDir={'row'} alignItems={'center'}>
                    { isMobile ? (
                    <Tooltip label={t('my-reading-list')}>
                        <IconButton mr={2} border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={() => setDrawerOpen(true)} aria-label='Favorite' icon={<BsListColumns />} />
                    </Tooltip>
                    ) : (
                        <Button mr={2} border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={() => setDrawerOpen(true)} aria-label='Favorite' leftIcon={<BsListColumns />}>{t('my-library-title')}</Button>
                    ) }
                    <Box >
                        <LangSelect />
                    </Box>
                    <Box pl='2' mr='2'>
                        {isAuthenticated && user && (
                            <UserMenu user={user} />
                        )}
                    </Box>
                </Flex>
            </Flex>
            <Drawer placement={'right'} onClose={() => setDrawerOpen(false)} isOpen={isDrawerOpen}>
                <DrawerOverlay />
                <DrawerContent w={'250px'}>
                    <ArticleList />
                </DrawerContent>
            </Drawer>
        </Box>
    );
}

export default MiniNav;