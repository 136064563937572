import { Card, Box, CardBody, Heading, Image, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const FeatureCards = () => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <Box p={10} maxWidth={'1200px'}>
                <Card borderRadius={'10px'} boxShadow={'5px 5px 10px #ccc'}>
                    <CardBody pt={0} pl={0} pr={0}>
                        <Box className="intro-card-bg" p={8} borderRadius={'10px 10px 0 0'}>
                            <Image src={`/custom_summary_${i18n.language == 'en' ? 'en' : 'cn'}.jpg`} />
                        </Box>
                        <Stack mt='6' spacing='3' px={8} py={2}>
                            <Heading size='md'>{t('custom-summary-label')}</Heading>
                            {/* <Text>
                                Description for the custom summary.
                            </Text> */}
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
            <Box my={5} p={10} maxWidth={'1200px'}>
                <Card borderRadius={'10px'} boxShadow={'5px 5px 10px #ccc'}>
                    <CardBody pt={0} pl={0} pr={0}>
                        <Box className="intro-card-bg" p={8} borderRadius={'10px 10px 0 0'}>
                            <Image src={`/rqa_${i18n.language == 'en' ? 'en' : 'cn'}.jpg`} />
                        </Box>
                        <Stack mt='6' spacing='3' px={8} py={2}>
                            <Heading size='md'>{t('video-title-rqa')}</Heading>
                            {/* <Text>
                                Description for the RQA.
                            </Text> */}
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
            <Box my={5} p={10} maxWidth={'1200px'}>
                <Card borderRadius={'10px'} boxShadow={'5px 5px 10px #ccc'}>
                    <CardBody pt={0} pl={0} pr={0}>
                        <Box className="intro-card-bg" p={8} borderRadius={'10px 10px 0 0'}>
                            <Image src={`/topic_highlighting_${i18n.language == 'en' ? 'en' : 'cn'}.jpg`} />
                        </Box>
                        <Stack mt='6' spacing='3' px={8} py={2}>
                            <Heading size='md'>{t('topic-highlighting')}</Heading>
                            {/* <Text>
                                Description for the Topic Highlighting.
                            </Text> */}
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
            <Box my={5} p={10} maxWidth={'1200px'}>
                <Card borderRadius={'10px'} boxShadow={'5px 5px 10px #ccc'}>
                    <CardBody pt={0} pl={0} pr={0}>
                        <Box className="intro-card-bg" p={8} borderRadius={'10px 10px 0 0'}>
                            <Image src={`/highlights_context_${i18n.language == 'en' ? 'en' : 'cn'}.jpg`} />
                        </Box>
                        <Stack mt='6' spacing='3' px={8} py={2}>
                            <Heading size='md'>{t('highlighting-context')}</Heading>
                            {/* <Text>
                                Description for the Highlights For Related Context.
                            </Text> */}
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
            <Box my={5} p={10} maxWidth={'1200px'}>
                <Card borderRadius={'10px'} boxShadow={'5px 5px 10px #ccc'}>
                    <CardBody pt={0} pl={0} pr={0}>
                        <Box className="intro-card-bg" p={8} borderRadius={'10px 10px 0 0'}>
                            <Image src={`/inspiration_${i18n.language == 'en' ? 'en' : 'cn'}.jpg`} />
                        </Box>
                        <Stack mt='6' spacing='3' px={8} py={2}>
                            <Heading size='md'>{t('video-title-inspiration')}</Heading>
                            {/* <Text>
                                Description for the Inspiration.
                            </Text> */}
                        </Stack>
                    </CardBody>
                </Card>
            </Box>
        </>
    );
}

export default FeatureCards;