import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { appLoadingState, articlePreviewModalOpenStatus, currentDirectoryIdState, duplicatedPendingArticleId, holdingForUploadingFiles, isQuickUploader, uploadingFile } from '../../atoms/rootAtom';
import { Button, Card, CardBody, CardFooter, Text, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Box, Flex, Tag, TagLabel, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FileService } from '../../services/fileService';
import { FileDetails } from '../../types/FileDetails';
import { useTranslation } from 'react-i18next';
import { dateToString } from '../../services/dateTimeConversion';
import useUnprocessedFileHandler from '../../hooks/useUnprocessedFileHandler';
import { ClientError } from '../../utils/clientError';
import { useNavigate } from "react-router-dom";
import useDirectories from '../../hooks/useDirectories';

const ArticlePreviewModal = () => {
    const [openStatus, setOpenStatus] = useRecoilState(articlePreviewModalOpenStatus);
    const [isQuickUploaderComponent, setIsQuickUploaderComponent] = useRecoilState(isQuickUploader);
    const { setFilesInCurrentDirectory } = useDirectories();
    const setUploadingFile = useSetRecoilState(uploadingFile);
    const duplicatedArticleId = useRecoilValue(duplicatedPendingArticleId);
    const [selectedResult, setSelectedResult] = useState<FileDetails | null>(null);
    const [onHoldArticles, setOnHoldArtilces] = useRecoilState(holdingForUploadingFiles);
    const { t } = useTranslation();
    const toast = useToast();
    const { addNeededFilesToPoller } = useUnprocessedFileHandler();
    const setduplicatedPendingArticleId = useSetRecoilState(duplicatedPendingArticleId);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const setAppLoadingState = useSetRecoilState(appLoadingState);
	const navigate = useNavigate();
    const currentDirectoryId = useRecoilValue(currentDirectoryIdState);


    useEffect(() => {
        const getFilesInfo = async () => {
            const fileDetails = await FileService.getFilesDetails([duplicatedArticleId]);
            if (fileDetails.length > 0) {
                setSelectedResult(() => fileDetails[0]);
            }
        }

        if (duplicatedArticleId != '') {
            getFilesInfo();
        }
    }, [duplicatedArticleId])

    // const acceptOnClick = async () => {
    //     await UserFileDataService.toggleIsInDrive(duplicatedArticleId);
    //     setDriveFiles((prev) => [...prev, selectedResult]);
    //     setAppLoadingState(false);
    //     setOpenStatus(false);
    //     setOnHoldArtilces([]);
    //     setduplicatedPendingArticleId('');
    //     setIsQuickUploaderComponent(false);
    // }

    const cancelOnClick = () => {
        setAppLoadingState(false);
        setOpenStatus(false);
        setOnHoldArtilces([]);
        setduplicatedPendingArticleId('');
        setIsQuickUploaderComponent(false);
        navigate(`/my-library`);
    }

    const contiueOnClick = async () => {
        try {
            setIsUploading(true);
            const response = await FileService.uploadFiles(onHoldArticles, currentDirectoryId);
            const responseFile = response[0];

            if (responseFile.isSuccessful) {
                addNeededFilesToPoller(responseFile.file);
                setFilesInCurrentDirectory((prevFiles) => [responseFile.file, ...prevFiles]);

                if (isQuickUploaderComponent) {
                    setUploadingFile(responseFile.file);
                }
            } else {
                let message = "Error uploading file: " + responseFile.identifier;
                if (responseFile.error && typeof responseFile.error === 'string') {
                    message += ". " + responseFile.error;
                }
                toast({
                    title: "File Upload Error",
                    description: message,
                    status: "error",
                });
            }
        } catch (error) {
            new ClientError(error).toast();
        } finally {
            setIsUploading(false);
            setOpenStatus(false);
            setduplicatedPendingArticleId('');
            setOnHoldArtilces([]);
            setIsQuickUploaderComponent(false);
        }
    }


    return (
        <Modal closeOnOverlayClick={false} onClose={() => setOpenStatus(false)} size={'xl'} isOpen={openStatus} scrollBehavior={'inside'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={'14px'}>{t('identified-article-label')}</ModalHeader>
                {selectedResult &&
                    <ModalBody w={'full'}>
                        <Card
                            direction={{ base: 'column', sm: 'row' }}
                            overflow='hidden'
                            variant='outline'
                            mb={2}
                        >
                            <Stack>
                                <CardBody>
                                    <Heading size='md'>{selectedResult.documentTitle}</Heading>
                                    <Box my={5}>
                                        {selectedResult.authors?.map(author => (
                                            <Tag key={author.id} size='xs' colorScheme='gray' borderRadius='5' mr={2} p={'2px 5px'}>
                                                {/* <FaUser /> */}
                                                <TagLabel fontSize={12}>{author.author}</TagLabel>
                                            </Tag>
                                        ))}
                                    </Box>
                                    <Flex flexDir={'row'}>
                                        <Heading size="xs" color={"gray.500"} mr={5}>
                                            {t('created-on-label')}: {dateToString(new Date(selectedResult.createdAt))}
                                        </Heading>
                                        <Heading size="xs" color={"gray.500"}>
                                            {t('updated-at-label')}: {dateToString(new Date(selectedResult.updatedAt))}
                                        </Heading>
                                    </Flex>
                                    <Box mt={5} h={'400px'} overflow={'auto'}>
                                        <Text py='2'>
                                            <b>Summary:</b> {selectedResult.dooyeedSummary}
                                        </Text>
                                    </Box>
                                </CardBody>
                                <CardFooter>
                                    <Flex w={'full'} flexDir='row' justifyContent='space-between'>
                                        {/* <Box>
                                            <Button leftIcon={<ViewIcon />} size={'sm'} mr={2} onClick={() => setOpenStatus(false)}>Preview</Button>
                                        </Box> */}
                                        <Box>
                                            
                                            {/* <Button isLoading={isUploading} size={'sm'} mr={2} colorScheme='yellow' onClick={acceptOnClick}>{t('accept-label')}</Button> */}
                                            <Button isLoading={isUploading} size={'sm'} colorScheme='red' onClick={contiueOnClick}>{t('continue-uploading-label')}</Button>
                                        </Box>
                                        <Box>
                                            <Button isLoading={isUploading} size={'sm'} onClick={cancelOnClick}>{t('choose-existing-article')}</Button>
                                        </Box>
                                    </Flex>

                                </CardFooter>
                            </Stack>
                        </Card>

                    </ModalBody>
                }

            </ModalContent>
        </Modal>
    );
}

export default ArticlePreviewModal;