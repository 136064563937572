import { useRecoilState } from 'recoil';
import { currentDirectoryIdState, myDriveDirectoriesState } from '../atoms/rootAtom';
import { Directory, DirectoryWithChildren } from '../types/Directory';
import { useEffect, useState } from 'react';
import { FileDetails } from '../types/FileDetails';

const useDirectories = () => {
	const [myDriveDirectories, setMyDriveDirectories] = useRecoilState(myDriveDirectoriesState);
	const [currentDirectoryId, setCurrentDirectoryId] = useRecoilState(currentDirectoryIdState);
	const [currentDirectory, setCurrentDirectory] = useState<DirectoryWithChildren>({
		id: null,
		childDirectories: [],
		files: []
	} as DirectoryWithChildren);

	useEffect(() => {
		const directory = myDriveDirectories.get(currentDirectoryId);
		if (directory) {
			setCurrentDirectory(directory);
		}
	}, [currentDirectoryId, myDriveDirectories]);

	function setChildDirectoriesInCurrentDirectory(newDirectoriesSetter: Directory[] | ((oldDirectories: Directory[]) => Directory[])) {
		setMyDriveDirectories((oldDirectories) => {
			const newDirectories = new Map(oldDirectories);
			const currentDirectory = newDirectories.get(currentDirectoryId);

			if (currentDirectory) {
				if (typeof newDirectoriesSetter === 'function') {
					currentDirectory.childDirectories = newDirectoriesSetter(currentDirectory.childDirectories);
				} else {
					currentDirectory.childDirectories = newDirectoriesSetter;
				}

				newDirectories.set(currentDirectoryId, currentDirectory);
			}

			return newDirectories;
		});
	}
	
	function setFilesInCurrentDirectory(newFilesSetter: FileDetails[] | ((oldFiles: FileDetails[]) => FileDetails[])) {
		setMyDriveDirectories((oldDirectories) => {
			const newDirectories = new Map(oldDirectories);
			const currentDirectory = newDirectories.get(currentDirectoryId);

			if (currentDirectory) {
				if (typeof newFilesSetter === 'function') {
					currentDirectory.files = newFilesSetter(currentDirectory.files);
				} else {
					currentDirectory.files = newFilesSetter;
				}

				newDirectories.set(currentDirectoryId, currentDirectory);
			}

			return newDirectories;
		});
	}

	function updateFilesInDirectories(newFiles: FileDetails | FileDetails[]) {
		if (!Array.isArray(newFiles)) {
			newFiles = [newFiles];
		}

		setMyDriveDirectories((oldDirectories) => {
			const newDirectories = new Map(oldDirectories);
			for (const directory of newDirectories.values()) {
				directory.files = directory.files.map((file) => {
					const newFile = (newFiles as FileDetails[]).find((newFile) => newFile.id === file.id);
					return newFile ? newFile : file;
				});
			}

			return newDirectories;
		});
	}

	function addEditDirectoryInState(newDirectory: DirectoryWithChildren) {
		setMyDriveDirectories((oldDirectories) => {
			const newDirectories = new Map(oldDirectories);
			newDirectories.set(newDirectory.id, newDirectory);
			return newDirectories;
		});
	}

	return { 
		currentDirectory,
		addEditDirectoryInState,
		currentDirectoryId,
		setCurrentDirectoryId,
		setFilesInCurrentDirectory,
		setChildDirectoriesInCurrentDirectory,
		updateFilesInDirectories,
	};
};

export default useDirectories;