import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Stack,
	useToast,
} from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import LatexSupportedText from "../LatexSupportedText";
import { Result } from "../../types/Search";
import { ViewIcon } from "@chakra-ui/icons";
import { SearchService } from "../../services/searchService.ts";
import { useSetRecoilState } from "recoil";
import { currentOpenedArticleIdState, myDriveFilesState, selectedArticlesState } from "../../atoms/rootAtom.ts";
import useUnprocessedFileHandler from "../../hooks/useUnprocessedFileHandler.ts";
import { ClientError } from "../../utils/clientError.ts";
import { useNavigate } from "react-router-dom";
import { ProcessingStatus } from "../../types/FileProcessingStatus.ts";

const readableOnDoenba = [
	"https://arxiv.org/",
]

interface Props {
	result: Result;
}
const ResultCard = ({ result }: Props) => {
	const toast = useToast();
	const navigate = useNavigate();
	const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
	const [isSummaryOverflowing, setIsSummaryOverflowing] = useState(false);
	const [isReadableOnDoenba, setIsReadableOnDoenba] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const { addNeededFilesToPoller } = useUnprocessedFileHandler();
	const setDriveFiles = useSetRecoilState(myDriveFilesState);
	const setCurrentOpenedArticleIdState = useSetRecoilState(currentOpenedArticleIdState);
	const setSelectedArticlesState = useSetRecoilState(selectedArticlesState);
	const summaryRef = useRef<HTMLDivElement>(null);

	const toggleIsSummaryExpanded = () => {
		setIsSummaryExpanded(!isSummaryExpanded);
	};

	const checkOverflow = () => {
		if (summaryRef.current) {
			const isOverflowing = summaryRef.current.scrollHeight > summaryRef.current.clientHeight;
			setIsSummaryOverflowing(isOverflowing);
		}
	};

	useEffect(() => {
		const observer = new ResizeObserver(() => {
			checkOverflow();
		});

		if (summaryRef.current) {
			observer.observe(summaryRef.current);
		}

		requestAnimationFrame(() => {
			checkOverflow();
		});

		return () => {
			observer.disconnect();
		};
	}, [summaryRef, result.abstract]);

	useEffect(() => {
		if (result.url) {
			const isReadable = readableOnDoenba.some((site) =>
				result.url?.includes(site)
			);

			setIsReadableOnDoenba(isReadable);
		}
	}, [result.url]);

	const handleReadInDoenbaClick = async (result: Result) => {
		setIsUploading(true);
		
		try {
			const responseFile = await SearchService.uploadSearchResult(result.id);

			if (responseFile.isSuccessful) {
				if (responseFile.file.processingStatus !== ProcessingStatus.PENDING) {
					addNeededFilesToPoller(responseFile.file);
					setDriveFiles((prev) => [responseFile.file, ...prev]);
				}

				setCurrentOpenedArticleIdState(responseFile.file.id);
				setSelectedArticlesState([responseFile.file]);
				navigate('/reader');
			} else {
				let message = "Error uploading file: " + responseFile.identifier;
				if (responseFile.error && typeof responseFile.error === 'string') {
						message += ". " + responseFile.error;
				}
				toast({
						title: "File Upload Error",
						description: message,
						status: "error",
				});
			}
		} catch (err) {
			new ClientError(err).toast();
		} finally {
			setIsUploading(false);
		}
	}

	return (
		<AccordionItem>
			<h2>
				<AccordionButton>
					<Box as="span" flex="1" textAlign="left" fontSize={"lg"}>
						<LatexSupportedText>
							{result.name ?? "No title available"}
						</LatexSupportedText>
					</Box>
					<AccordionIcon />
				</AccordionButton>
			</h2>
			<AccordionPanel>
				<Flex flexDir={"row"}>
					<Box flex={"1 auto"} fontSize={"md"}>
						<Flex flexDir={"column"} gap={2}>
							<Box>
								{/* {result.authors?.map((author) => (
									<Tag
										key={author.id}
										size="xs"
										colorScheme="gray"
										borderRadius="5"
										mr={2}
										p={"2px 5px"}
									>
										<TagLabel fontSize={12}>{author.author}</TagLabel>
									</Tag>
								))} */}
							</Box>
							<Box
								ref={summaryRef}
								sx={{
									display: "-webkit-box",
									WebkitLineClamp: isSummaryExpanded ? "unset" : 5,
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
									textOverflow: isSummaryExpanded ? "unset" : "ellipsis",
									whiteSpace: "normal",
								}}
							>
								<LatexSupportedText>
									{result.abstract ?? "No abstract available"}
								</LatexSupportedText>
							</Box>
							{(isSummaryOverflowing || isSummaryExpanded) && (
								<Button
									size="xs"
									onClick={toggleIsSummaryExpanded}
									mt={2}
									variant="link"
								>
									{isSummaryExpanded ? "Show less" : "See all"}
								</Button>
							)}
						</Flex>
					</Box>
					<Box w={"150px"}>
						<Stack spacing={1} direction="column" align="left">
							{isReadableOnDoenba && (
								<Button
									leftIcon={<ViewIcon />}
									size="xs"
									isLoading={isUploading}
									onClick={() => handleReadInDoenbaClick(result)}
								>
									Read
								</Button>
							)}
							{result.url && (
								<Button
									as="a"
									rel="noopener noreferrer"
									leftIcon={<FaExternalLinkAlt />}
									href={result.url}
									target="_blank"
									size="xs"
								>
									{isReadableOnDoenba ? "External" : "Open"}
								</Button>
							)}
						</Stack>
					</Box>
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
};

export default ResultCard;
