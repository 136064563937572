import axios from "axios";

import { BASE_URL } from ".";
import { FileDetails } from "../types/FileDetails";

interface SummaryRequest {
	fileId: string;
	summaryLength: number;
	summaryLanguage: string;
	summaryLevel: string;
	summaryTopic: string;
}

export class ArticleProcessingService {
	public static async getSummary(data: SummaryRequest) {
		const response = await axios.post(BASE_URL + "/articleProcessing/getSummary", data);
		return response.data;
	}

	public static async requeueArticleForProcessing(fileId: string) {
		const response = await axios.post<FileDetails>(BASE_URL + `/articleProcessing/requeue/${fileId}`);
		return response.data;
	}
}