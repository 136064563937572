import { User } from "@auth0/auth0-react";
import {
	Avatar,
	Box,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
	useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LogoutModal from "./LogoutModal";
import { useTranslation } from "react-i18next";

interface Props {
	user: User;
}

const UserMenu = ({ user }: Props) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const navToMyDrive = () => navigate("/my-library");
	const navToAbout = () => navigate("/about");

	return (
		<>
			<Menu>
				<MenuButton
					as={IconButton}
					border="25px"
					aria-label="Options"
					icon={<Avatar size="sm" name={user.name} src={user.picture} />}
					variant="outline"
				>
					{"Profile"}
				</MenuButton>
				<MenuList zIndex={3}>
					<MenuGroup title={t('mini-nav-tools-label')}>
						<MenuItem onClick={navToMyDrive}>{t('my-library-title')}</MenuItem>
					</MenuGroup>
					<MenuGroup title={t('mini-nav-others-label')}>
						<MenuItem onClick={navToAbout}>{t('mini-nav-about-label')}</MenuItem>
					</MenuGroup>
					<MenuGroup title={t('mini-nav-profile-label')}>
						<Box px="3">{user.user_metadata.first_name && user.user_metadata.last_name ? `${user.user_metadata.first_name} ${user.user_metadata.last_name}` : user.name}</Box>
						<Box px="3" color="gray.400">
							{user.email}
						</Box>
						<MenuDivider />
						<MenuItem onClick={onOpen}>{t('logout-label')}</MenuItem>
					</MenuGroup>
				</MenuList>
			</Menu>

			<LogoutModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export default UserMenu;
