import { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Box, Button, IconButton, InputGroup, InputRightElement, Stack, List, ListIcon, ListItem, Text, Input, Tooltip, Menu, MenuButton, MenuItem, MenuList, FormControl, FormLabel, Switch, Center, Heading, Progress, Avatar, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { FaPaperPlane } from 'react-icons/fa';
import { BiMessageAltDetail } from 'react-icons/bi';
import { ConversationService } from '../../services/conversationService';
import { Conversation, ConversationHistory, MessageContext } from '../../types/Conversation';
import { ChatIcon, DeleteIcon, DownloadIcon, HamburgerIcon } from '@chakra-ui/icons';
import { formatDate } from '../../utils/dateTimeConversion';
import { MdEdit, MdMenuBook } from 'react-icons/md';
import { useRecoilState } from 'recoil';
import { currentOpenedArticleIdState, quoteSentenceIndexesState, selectedArticlesState, selectedMessageBubbleId } from '../../atoms/rootAtom';
import { ClientError } from '../../utils/clientError';
import useHighlights, { HighlightType } from '../../hooks/useHighlights';
import DisabledFeatureTooltip from '../DisabledFeatureTooltip';
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import SessionDrawer from './SessionDrawer';
import useUnprocessedFileHandler from '../../hooks/useUnprocessedFileHandler';
import SummaryModal from '../SummaryModal';
import { FileService } from '../../services/fileService';
import TextareaAutosize from "react-textarea-autosize";
import Inspiration from './Inspiration';
import { containsFeatures } from '../../services/articleFeatureService';
import { DisabledTooltipText } from '../../types/DisabledTooltipText';
import { useTranslation } from "react-i18next";
import { ArticleFeature } from '../../types/ArticleFeature';
import { FileDetails } from '../../types/FileDetails';
import { v4 as uuidv4 } from 'uuid';
import QuoteSentences from './QuoteSentences';
import { Sentences } from '../../types/Sentence';
import Message from './Message';

const TaskQATaskView = () => {
    const { t, i18n } = useTranslation();
    const [messages, setMessages] = useState<ConversationHistory[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [currentConversation, setCurrentConversation] = useState<Conversation | null>(null);
    const currentConversationRef = useRef(currentConversation);
    const [allConversations, setAllConversations] = useState<Conversation[]>([]);

    const [isResponding, setIsResponding] = useState<boolean>(false);
    const [conversationStatusText, setConversationStatusText] = useState<string>(null);
    const [isPendingFullResponse, setIsPendingFullResponse] = useState<boolean>(false);
    // const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    // const recognition = useRef<any | null>(null);
    const chatWindowRef = useRef<HTMLTextAreaElement | null>(null);
    const [sessionDrawerOpenStatus, setSessionDrawerOpenStatus] = useState<boolean>(false);
    const [fetchingConversationStatus, setFetchingConversationStatus] = useState<boolean>(false);
    const [showAllQA, setShowAllQA] = useState<boolean>(false);
    const [selectedMessageId, setSelectedMessageId] = useRecoilState(selectedMessageBubbleId);
    const [selectedQuoteMessageId, setSelectedQuoteMessageId] = useState<string>('');
    const [isAllQAHovering, setIsAllQAHovering] = useState(false);

    const [showSummaryModal, setShowSummaryModal] = useState<boolean>(false);
    const [selectedResult, setSelectedResult] = useState<FileDetails | null>(null);
    const [isInspirationEnabled, setIsInspirationEnabled] = useState<boolean>(false);
    const [inspirationText, setInspirationText] = useState<string | null>(null);

    const [currentOpenedArticleId, setCurrentOpenedArticleId] = useRecoilState(currentOpenedArticleIdState);
    const { clearHighlights, highlightSentences } = useHighlights();

    const [selectedArticles, setSelectedArticles] = useRecoilState(selectedArticlesState);

    const { addNeededFilesToPoller, hasFileProcessedSuccessfully } = useUnprocessedFileHandler();

    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    const [conversationTitle, setConversationTitle] = useState<string>('');

    const [conversationDeletionModalIsOpen, setConversationModalIsOpen] = useState<boolean>(false);
    const [deletingMessageId, setDeletingMessageId] = useState<string>('');
    const [articleSentences, setArticleSentences] = useState<Sentences>({});
	const [quoteSentenceIndexes, setQuoteSentenceIndexes] = useRecoilState(quoteSentenceIndexesState);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ block: "nearest" });
    };

    const filteredConversations = useMemo(() => {
        if (showAllQA || currentOpenedArticleId === '') {
            return allConversations;
        } else {
            return allConversations.filter(conversation => {
                return conversation.files[0].id == currentOpenedArticleId;
            })
        }
    }, [showAllQA, allConversations, currentOpenedArticleId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const getConversations = async () => {
            setFetchingConversationStatus(true);
            try {
                const conversationsResult = await ConversationService.getConversations();
                setAllConversations(conversationsResult);
            } catch (error) {
                new ClientError(error).toast();
            } finally {
                setFetchingConversationStatus(false);
            }
        }
        getConversations();
    }, []);

    useEffect(() => {
        setConversationTitle(filteredConversations[0]?.title);
    }, [filteredConversations]);

    useEffect(() => {
        if (currentConversation && !currentConversation.files.find(file => file.id === currentOpenedArticleId)) {
            setCurrentConversation(null);
            setMessages([]);
        }
    }, [currentConversation, currentOpenedArticleId]);

    useEffect(() => {
        const getFilesInfo = async () => {
            const fileDetails = await FileService.getFilesDetails([currentOpenedArticleId]);
            if (fileDetails.length > 0) {
                setSelectedResult(() => fileDetails[0]);
            }
        }
        getFilesInfo();
    }, [currentOpenedArticleId, selectedArticles])

    useEffect(() => {
        // Handle updating the articleSentences when the selectedResult changes
        // Clear existing quoteSentenceIndexes

        async function fetchArticleSentences() {
            try {
                const response = await FileService.getSentences(selectedResult.id);
                setArticleSentences(response);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        clearQuoteSentenceIndexes();

        if (!containsFeatures(selectedResult, ArticleFeature.QA)) {
            setArticleSentences({});
        } else {
            fetchArticleSentences();
        }
    }, [selectedResult]);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && event.shiftKey === false) {
            event.preventDefault();
            handleSendMessage("user");
            // setIsTyping(false);
            if (chatWindowRef.current) {
                chatWindowRef.current.blur();
            }
        }
    };

    const handlePartialBotResponseFn = (fakeMessageId: string) => (partialResponse: string) => {
        if (!isResponding) {
            setIsResponding(true);
        }

        const responseMessageId = 'response-' + fakeMessageId;

        try {
            setMessages((prevMessages) => {
                const existingNewMessage = prevMessages.find((msg) => msg.id === responseMessageId);

                if (existingNewMessage) {
                    return prevMessages.map((msg) => {
                        if (msg.id === responseMessageId) {
                            return {
                                ...msg,
                                message: msg.message + partialResponse,
                            };
                        }
                        return msg;
                    });
                } else {
                    const newMessage: ConversationHistory = {
                        id: responseMessageId,
                        message: partialResponse,
                        sender: 'bot',
                        createdAt: new Date().toString(),
                        updatedAt: new Date().toString(),
                        isTempMessage: true,
                    }

                    return [
                        ...prevMessages,
                        newMessage,
                    ];
                }
            });
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    function handleConversationHistoriesResponse(conversationPieces: ConversationHistory[]) {
        if (conversationPieces.length > 0) {
            conversationPieces.forEach((message) => {
                highlightSentences(message.highlight, HighlightType.TaskQA);
            })
        }

        const aiResponse = conversationPieces[1];
        if (aiResponse && aiResponse.inspirationText) {
            setInspirationText(aiResponse.inspirationText);
        } else {
            setInspirationText(null);
        }

        setMessages([
            ...messages,
            ...conversationPieces
        ]);

        setIsPendingFullResponse(false);
        setIsResponding(false);
        setConversationStatusText(null);
    }

    const handleSendMessage = async (messageContext: MessageContext, message?: string) => {
        const messageToSend = message || newMessage;

        if (messageToSend.trim() === '') return;
        if (isSendingDisabled) return;

        setIsPendingFullResponse(true);
        setNewMessage('');
        setQuoteSentenceIndexes([]);

        if(quoteSentenceIndexes.length == 0) {
            clearHighlights(HighlightType.Quotation);
        }

        let conversationId = currentConversation?.id;
        if (!currentConversation) {
            const selectedArticleIds = selectedArticles.map(article => article.id);

            const newConversation = await ConversationService.initConversation(selectedArticleIds);
            setAllConversations([
                newConversation,
                ...allConversations,
            ]);

            setMessages([]);
            clearHighlights([HighlightType.TaskQA, HighlightType.Quotation]);
            setCurrentConversation(newConversation);
            conversationId = newConversation.id;
        }

        try {
            const fakeMessageId = uuidv4();

            const tempMessage: ConversationHistory = {
                id: 'request-' + fakeMessageId,
                sender: 'user',
                message: messageToSend,
                createdAt: new Date().toString(),
                updatedAt: new Date().toString(),
                messageContext,
                isTempMessage: true,
                quoteSentenceIndexes,
            };
            setMessages([...messages, tempMessage]);

            await ConversationService.addToConversation(
                conversationId,
                messageToSend,
                isInspirationEnabled,
                messageContext,
                i18n.language,
                quoteSentenceIndexes,
                handlePartialBotResponseFn(fakeMessageId),
                handleConversationHistoriesResponse,
                setConversationStatusText,
            );
        } catch (error) {
            new ClientError(error).toast();
            setIsResponding(false);
            setIsPendingFullResponse(false);
            setConversationStatusText(null);
        }
    };

    const addNewConversation = async () => {
        const selectedArtileIds = selectedArticles.map(article => article.id);
        try {
            const newConversation = await ConversationService.initConversation(selectedArtileIds);
            setAllConversations([
                newConversation,
                ...allConversations,
            ]);
            setMessages([]);
            clearHighlights([HighlightType.TaskQA, HighlightType.Quotation]);
            setCurrentConversation(newConversation);
        } catch (error) {
            new ClientError(error).toast();
        }
        setSessionDrawerOpenStatus(false);
        setIsInspirationEnabled(false);
        setInspirationText('');
    }

    const conversationOnClick = async (conversation: Conversation) => {
        try {
            setFetchingConversationStatus(true);
            setCurrentOpenedArticleId(conversation.files[0].id);
            setSelectedArticles([conversation.files[0]]);

            const conversationInfo = await ConversationService.getConversationInfo(conversation.id);

            setSessionDrawerOpenStatus(false);
            setCurrentConversation(conversation);
            setMessages(conversationInfo.history);
            clearHighlights([HighlightType.TaskQA, HighlightType.Quotation]);
        } catch (error) {
            new ClientError(error).toast();
        } finally {
            setFetchingConversationStatus(false);
        }
    }

    const deleteConversation = async (conversationId?: string) => {
        try {
            const conversationIdToDelete = conversationId || currentConversation.id;

            await ConversationService.deleteConversation(conversationIdToDelete);
            const newConversations = allConversations.filter((conversation) => conversation.id !== conversationIdToDelete);
            setAllConversations(newConversations);

            if (currentConversation?.id === conversationIdToDelete) {
                setCurrentConversation(null);
                setMessages([]);
            }
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    // const startListening = () => {
    //     setIsSpeaking(true);
    //     if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
    //         recognition.current = new ((window as any).SpeechRecognition || (window as any).webkitSpeechRecognition)();
    //         recognition.current.onresult = (event) => {
    //             const result = event.results[0][0].transcript;
    //             setNewMessage(result);
    //             handleSendMessage();
    //             setIsSpeaking(false);
    //         };
    //         recognition.current.start();
    //     } else {
    //         alert('Speech recognition is not supported in your browser.');
    //         setIsSpeaking(false);
    //     }
    // };

    // const stopListening = () => {
    //     if (recognition.current) {
    //         recognition.current.stop();
    //         recognition.current = null;
    //     }
    //     setIsSpeaking(false);
    // };

    useEffect(() => {
        currentConversationRef.current = currentConversation;
    }, [currentConversation]);

    const downloadTranscript = async (conversationId?: string) => {
        const historyForTranscript = await ConversationService.getHistoryForTranscript(conversationId || currentConversation.id);
        const transcript = await historyForTranscript.transcript.map((message) => {
            const formattedDate = formatDate(message.createdAt);
            const formattedSender = message.sender === 'user' ? 'User' : 'Doenba';

            return `${formattedDate}\n${formattedSender}: ${message.message}`;
        }).join('\n\n');

        const element = document.createElement('a');
        const file = new Blob([transcript], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${historyForTranscript.title}.txt`;
        document.body.appendChild(element);
        element.click();
    }

    // const speak = (sentence: string) => {
    //     const synth = window.speechSynthesis;
    //     const utterance = new SpeechSynthesisUtterance(sentence);
    //     synth.speak(utterance);
    // };

    const getSummary = () => {
        setShowSummaryModal(true);
    }

    const onGetAnswerForInspirationClick = () => {
        if (!isInspirationEnabled || !inspirationText) {
            return;
        }

        handleSendMessage("inspiration", inspirationText);
        setInspirationText(null);
    }

    useEffect(() => {
        addNeededFilesToPoller(selectedArticles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedArticles]);

    const isTaskQADisabled = !containsFeatures(selectedResult, ArticleFeature.QA);
    const isTypingDisabled = isTaskQADisabled || selectedArticles.some(article => !hasFileProcessedSuccessfully(article));
    const isSendingDisabled = isPendingFullResponse || isTypingDisabled;

    const conversationTitleOnChange = () => {
        if (conversationTitle !== '') {
            setCurrentConversation({
                ...currentConversation,
                title: conversationTitle
            });
            setAllConversations(currentConversations =>
                currentConversations.map(conv =>
                    conv.id === currentConversation.id ?
                        { ...conv, title: conversationTitle } :
                        conv
                )
            )

            ConversationService.updateConversation(currentConversation.id, conversationTitle);
        }
    }

    const qaNameHandleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            conversationTitleOnChange();
            setIsRenaming(false);
        }
    };

    const confirmOnRenaming = () => {
        conversationTitleOnChange();
        setIsRenaming(false);
    }

    const cancelOnRenaming = () => {
        setConversationTitle(currentConversation?.title);
        setIsRenaming(false)
    }

    const promptForMessageDeletion = (id: string) => {
        setDeletingMessageId(id);
        setConversationModalIsOpen(true);
    }

    const confirmOnMessageDeletion = async () => {
        const originalMessages = [...messages];

        try {
            setConversationModalIsOpen(false);
            setMessages(messages.filter(message => message.id !== deletingMessageId));
            await ConversationService.deleteConversationMessage(deletingMessageId);
        } catch (error) {
            setMessages(originalMessages);
            new ClientError(error).toast();
        }
    }

    const cancelOnMessageDeletion = () => {
        setDeletingMessageId('');
        setConversationModalIsOpen(false);
    }

    const clearQuoteSentenceIndexes = () => {
        setQuoteSentenceIndexes([]);
    }

    return (
        <Flex flexDir="column" w={'100%'} h={'100%'}>
            {!fetchingConversationStatus &&
                <Flex flex={'1 auto'} py={'2'} flexDir={'column'} w={'100%'} h={'calc(100vh - 80px)'}>
                    <>
                        <Flex flexDir={'row'} justifyContent={'space-between'} h={'48px'} alignItems={'center'}>
                            <Flex flexDir={'row'} alignItems={'center'} w={'full'}>
                                {!isRenaming ? <ChatIcon color='gray.300' /> : <MdEdit color='gray.300' />}
                                {isRenaming &&
                                    <Flex ml={2} w={'full'} flexDir={'row'} alignItems={'center'} mr={2} >
                                        <Input
                                            mr={2}
                                            size={'sm'}
                                            placeholder={currentConversation?.title}
                                            // value={currentConversation.title}
                                            value={conversationTitle}
                                            onChange={(e) => setConversationTitle(e.target.value)}
                                            onKeyDown={qaNameHandleKeyDown} />
                                        <IconButton mr={2} size={'sm'} aria-label='confirm renaming' icon={<IoMdCheckmark />} onClick={confirmOnRenaming} />
                                        <IconButton size={'sm'} aria-label='cancel renaming' icon={<IoMdClose />} onClick={cancelOnRenaming} />
                                    </Flex>
                                }
                                {!isRenaming && <Text onDoubleClick={() => setIsRenaming(true)} ml={3} w={'full'}>{currentConversation?.title}</Text>}
                            </Flex>
                            {currentConversation && !isRenaming &&
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        aria-label='Options'
                                        icon={<HamburgerIcon />}
                                        variant='outline'
                                        size={'sm'}
                                        mx={2}
                                    />
                                    <MenuList>
                                        <MenuItem icon={<MdEdit fontSize={'16px'} />} onClick={() => setIsRenaming(true)}>
                                            {t('rename')}
                                        </MenuItem>
                                        <MenuItem icon={<DownloadIcon fontSize={'16px'} />} onClick={() => downloadTranscript()}>
                                            {t('download')}
                                        </MenuItem>
                                        <MenuItem icon={<DeleteIcon fontSize={'16px'} color={'red'} />} onClick={() => deleteConversation()}>
                                            {t('delete')}
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            }
                            {containsFeatures(selectedResult, ArticleFeature.Summary) &&
                                <Tooltip label={t('get-summary-label')}>
                                    {/* <IconButton border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={getSummary} aria-label='Demo' icon={<MdMenuBook />} /> */}
                                    <Button mr={2}
                                        leftIcon={<MdMenuBook />}
                                        my={'2'}
                                        px={10}
                                        size={'sm'}
                                        variant='outline'
                                        onClick={getSummary}
                                        border={'1px solid black'}>
                                        {t('custom-summary-label')}
                                    </Button>
                                </Tooltip>
                            }
                            <Button
                                my={'2'}
                                px={10}
                                size={'sm'}
                                leftIcon={<TbLayoutSidebarLeftCollapse />}
                                onClick={() => setSessionDrawerOpenStatus(true)}
                                isLoading={fetchingConversationStatus}
                                loadingText={t('study-sessions-label')}
                                border={'1px solid black'}>
                                {t('study-sessions-label')}
                            </Button>
                        </Flex>
                        {currentConversation &&
                            <Box flex={'1 auto'} w={'100%'} mt='5' h={`${57 - (selectedArticles.length * 2.75)}vh`} overflow={'hidden'}>
                                <Box h={'100%'} overflow={'auto'}>
                                    {messages.length > 0 &&
                                        <Stack spacing={3} ref={chatContainerRef}>
                                            {messages.map((message) => (
                                                <Message
                                                    key={message.id}
                                                    selectedFile={selectedResult}
                                                    message={message}
                                                    isSelected={selectedMessageId === message.id}
                                                    isQuoteSelected={selectedQuoteMessageId === message.id}
                                                    setSelectedMessageId={setSelectedMessageId}
                                                    setSelectedQuoteMessageId={setSelectedQuoteMessageId}
                                                    onDeleteMessageClick={promptForMessageDeletion}
                                                    articleSentences={articleSentences}
                                                />
                                            ))}
                                            {isPendingFullResponse && !isResponding &&
                                                <Box mb={10} px={5}>
                                                    <Flex flexDir={'row'} alignItems={'flex-start'} w={'full'}>
                                                        <Flex flexDir={'row'} alignItems={'flex-start'}>
                                                            <Avatar name='RA profile' src={'/doenba-ico.png'} size={'sm'} mr={2} />
                                                        </Flex>
                                                        <Flex flex={'1 auto'} flexDir={'column'}>
                                                            <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                <Heading size="sm" mb={2}>
                                                                    Doenba
                                                                </Heading>
                                                            </Flex>
                                                            <Box pl={3} pr={2} mb={4} borderLeft={'5px solid #346136'} py={5}>
                                                                {conversationStatusText && <Text mb={4}>{conversationStatusText}</Text>}
                                                                <Progress size='sm' isIndeterminate colorScheme='green.400' />
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            }
                                            <Box ref={messagesEndRef}></Box>
                                        </Stack>
                                    }
                                    {messages.length == 0 &&
                                        <Flex height={'full'} flexDir={'row'} justifyContent={'center'}>
                                            <Text color={'gray.300'}>- t{'no-conversation-history'} -</Text>
                                        </Flex>
                                    }
                                </Box>
                            </Box>
                        }
                    </>
                    {!currentConversation &&
                        <Flex w={'full'} justifyContent={'flex-end'} mb={4}>
                            <FormControl
                                width="auto"
                                display="flex"
                                alignItems="center"
                                justifyContent={'flex-end'}
                                onMouseEnter={() => setIsAllQAHovering(true)}
                                onMouseLeave={() => setIsAllQAHovering(false)}
                            >
                                <FormLabel htmlFor="allQA" mb="0">
                                    <Flex flexDir={"row"} alignItems={"center"}>
                                        <Text ml={"2"}>{t('all-study-sessions-label')}</Text>
                                    </Flex>
                                </FormLabel>
                                <Tooltip label={t('all-qa-tooltip')} isOpen={isAllQAHovering} mt={4}>
                                    <Switch
                                        colorScheme="yellow"
                                        id="allQA"
                                        isChecked={showAllQA}
                                        onChange={(e) => setShowAllQA(e.target.checked)}
                                    />
                                </Tooltip>
                            </FormControl>
                        </Flex>
                    }
                    {!currentConversation &&
                        <Box w='full' h={'full'}>
                            {/* <Button w='full' my={'2'} size={'md'} leftIcon={<BiCommentAdd />} onClick={addNewConversation} mb={'3'} border={'1px solid black'}>QA</Button> */}
                            <List overflow={'auto'} h={`calc(100% - 80px)`}>
                                {filteredConversations.map((conversation) => (
                                    <ListItem
                                        key={conversation.id}
                                        p='2'
                                        cursor={'pointer'}
                                        onClick={() => conversationOnClick(conversation)}
                                        bgColor={'initial'}
                                        _hover={{ background: 'gray.100' }}
                                    >
                                        <Flex direction='row' alignItems={'flex-start'}>
                                            <ListIcon mt='1' as={BiMessageAltDetail} fontSize={'25px'} color='gray.500' />
                                            <Flex flexDir={'column'}>
                                                <Text fontSize='md' mb={1} overflow='ellipse' fontWeight={'bold'} color={'gray.600'}>{conversation.title}</Text >
                                                <Text fontSize='xs' overflow='ellipse' color={'gray.400'}>{formatDate(conversation.updatedAt)}</Text >
                                            </Flex>
                                        </Flex>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    }
                    {(currentConversation || filteredConversations.length === 0) &&
                        <>
                            <Box pt={3}>
                                <Inspiration
                                    isEnabled={isInspirationEnabled}
                                    setIsEnabled={setIsInspirationEnabled}
                                    text={inspirationText}
                                    setText={setInspirationText}
                                    onGetAnswerClick={onGetAnswerForInspirationClick}
                                />
                            </Box>
                            <Box mt={isInspirationEnabled && inspirationText != null ? 1 : 3} position='relative' border={'1px solid black'} p={2} borderRadius={5}>
                                <QuoteSentences
                                    quoteSentenceIndexes={quoteSentenceIndexes}
                                    clearQuoteSentenceIndexes={clearQuoteSentenceIndexes}
                                    articleSentences={articleSentences} 
                                />
                                <InputGroup marginTop={1} >
                                    <DisabledFeatureTooltip
                                        isDisabled={!isTypingDisabled}
                                        text={isTaskQADisabled ? DisabledTooltipText.FeatureDisabled : DisabledTooltipText.StillProcessing}
                                    >
                                        {/* <Textarea
                                            className='conversationWindow'
                                            ref={chatWindowRef}
                                            isDisabled={isTypingDisabled}
                                            placeholder={"Enter your questions here..."}
                                            value={newMessage}
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            size='lg'
                                            border={null}
                                            resize={'none'}
                                            outline={null}
                                            _focusVisible={null}
                                            w='calc(100% - 50px)'
                                            rows={1}
                                            minH='38px' // Set minimum height to 1 row
                                            maxH='75px' // Set maximum height to 3 rows
                                            overflowY='auto' // Add scrolling when content exceeds maximum height
                                        /> */}
                                        <TextareaAutosize
                                            ref={chatWindowRef}
                                            disabled={isTypingDisabled}
                                            placeholder={t('enter-msg-here')}
                                            value={newMessage}
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                width: 'calc(100% - 50px)',
                                                resize: 'none',
                                                paddingTop: '8px',
                                                paddingBottom: '8px'
                                            }}
                                            minRows={1}
                                            maxRows={3}
                                            autoFocus />
                                    </DisabledFeatureTooltip>
                                    <InputRightElement w={'40px'} h={'100%'}>
                                        <Flex flexDir={'column'} h={'100%'} justifyContent={'flex-start'}>
                                            <Tooltip label='Ctrl + Enter' h={'32px'}>
                                                <IconButton
                                                    size={'sm'}
                                                    isDisabled={isSendingDisabled}
                                                    mb={'1'}
                                                    aria-label="Send"
                                                    colorScheme='yellow'
                                                    border={'1px solid black'}
                                                    icon={<FaPaperPlane />}
                                                    h={'32px'}
                                                    onClick={() => handleSendMessage("user")}
                                                />
                                            </Tooltip>
                                            {/* {!isSpeaking &&
                                                <Tooltip label='Voice Input'>
                                                    <IconButton
                                                        size={'sm'}
                                                        isDisabled={isSendingDisabled}
                                                        aria-label="Send"
                                                        icon={<MdKeyboardVoice />}
                                                        onClick={startListening}
                                                    />
                                                </Tooltip>
                                            }
                                            {isSpeaking &&
                                                <Tooltip label='Voice Input'>
                                                    <IconButton
                                                        size={'sm'}
                                                        aria-label="Send"
                                                        icon={<FaStop />}
                                                        onClick={stopListening}
                                                    />
                                                </Tooltip>
                                            } */}
                                        </Flex>
                                    </InputRightElement>
                                </InputGroup>
                            </Box>
                        </>
                    }
                </Flex>
            }
            {fetchingConversationStatus &&
                <Center w={'full'} h={'full'}>
                    <Heading size="md">
                        Getting ready for your Task Reading QA now.
                    </Heading>
                </Center>
            }
            <SessionDrawer
                isOpen={sessionDrawerOpenStatus}
                handleClose={() => setSessionDrawerOpenStatus(false)}
                currentConversation={currentConversation}
                conversations={filteredConversations}
                addNewConversation={addNewConversation}
                conversationOnClick={conversationOnClick}
                deleteConversation={deleteConversation}
                downloadTranscript={downloadTranscript}
                showAllQA={showAllQA}
                setShowAllQA={setShowAllQA}
            />
            <SummaryModal isOpen={showSummaryModal} handleClose={() => setShowSummaryModal(false)} file={selectedResult} />

            <Modal isOpen={conversationDeletionModalIsOpen} onClose={() => setConversationModalIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('message-deleting-label')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t('conversation-deleting-confirmation-message')}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={confirmOnMessageDeletion}>
                            {t('confirm-label')}
                        </Button>
                        <Button variant='ghost' onClick={cancelOnMessageDeletion}>{t('cancel-label')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
}

export default TaskQATaskView;
