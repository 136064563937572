import { Box, Heading, Text } from "@chakra-ui/react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

interface ConversationMarkdownProps {
	content: string;
}

function ConversationMarkdown({ content }: ConversationMarkdownProps) {
	const markdownComponents = {
		h1: ({ ...props }) => <Heading as="h1" size="xl" my={4} {...props} />,
		h2: ({ ...props }) => <Heading as="h2" size="lg" my={4} {...props} />,
		h3: ({ ...props }) => <Heading as="h3" size="md" my={3} {...props} />,
		h4: ({ ...props }) => <Heading as="h4" size="sm" my={2} {...props} />,
		h5: ({ ...props }) => <Heading as="h5" size="xs" my={2} {...props} />,
		h6: ({ ...props }) => <Heading as="h6" size="xs" my={1} {...props} />,
		p: ({ ...props }) => <Text my={2} {...props} />,
		ul: ({ ...props }) => <Box as="ul" pl={4} my={2} {...props} />,
		ol: ({ ...props }) => <Box as="ol" pl={4} my={2} {...props} />,
		li: ({ ...props }) => <Box as="li" my={1} {...props} />,
		blockquote: ({ ...props }) => (<Box as="blockquote" p={2} my={2} bg="gray.100" borderRadius={5} {...props} />),
		code: ({ ...props }) => <Box as="code" p={1} bg="gray.100" borderRadius={5} {...props} />,
		pre: ({ ...props }) => (<Box as="pre" p={2} my={2} bg="gray.100" borderRadius={5} whiteSpace='normal' {...props} />),
		a: ({ ...props }) => <Text as="a" color="blue.500" {...props} />,
		img: ({ ...props }) => <Box as="img" my={2} {...props} />,
		table: ({ ...props }) => <Box as="table" my={2} {...props} />,
		thead: ({ ...props }) => <Box as="thead" {...props} />,
		tbody: ({ ...props }) => <Box as="tbody" {...props} />,
		tr: ({ ...props }) => <Box as="tr" {...props} />,
		th: ({ ...props }) => <Box as="th" p={2} bg="gray.100" {...props} />,
		td: ({ ...props }) => <Box as="td" p={2} {...props} />,
	};

	return (
		<Markdown
			remarkPlugins={[remarkGfm, remarkMath]}
			rehypePlugins={[rehypeKatex]}
			components={markdownComponents}
		>
			{content}
		</Markdown>
	);
}

export default ConversationMarkdown;
