import { ArticleFeature } from "../types/ArticleFeature";

interface FileWithFeatures {
	features: string[];
}

export const containsFeatures = (file: FileWithFeatures, features: ArticleFeature[] | ArticleFeature) => {
	if (!Array.isArray(features)) {
		features = [features];
	}

	if (!file?.features) {
		return false;
	}

	return features.every((feature) => file.features.includes(feature));
}