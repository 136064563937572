import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export function useInterceptor() {
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	async function set() {
		axios.interceptors.request.clear();
		axios.interceptors.request.use(
			async (config) => {
				try {
					const token = await getAccessTokenSilently();
					if (token) {
						config.headers["Authorization"] = "Bearer " + token;
					}
				} catch (error) {
					console.error("Error getting access token:", error);
					await loginWithRedirect();
				}

				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		axios.interceptors.response.clear();
	}

	return set;
}
