import { Box, Breadcrumb, BreadcrumbItem, Flex, Heading, Text } from '@chakra-ui/react';
import { FaFolder } from 'react-icons/fa';
import { DirectoryWithChildren } from '../../types/Directory';
import { useTranslation } from 'react-i18next';

interface Props {
    currentDirectory: DirectoryWithChildren;
    setCurrentDirectoryId: (id: number) => void;
}

const DirectoryBreadCrumb = ({ currentDirectory, setCurrentDirectoryId }: Props) => {
    const { t } = useTranslation();

    const currentIsRoot = currentDirectory.id === null;
    const parentIsRoot = currentDirectory.parentId === null;
    const parentParentIsRoot = currentDirectory.parent?.parentId === null;

    const handleUpClick = () => {
        if (parentIsRoot) return;
        setCurrentDirectoryId(currentDirectory.parentId);
    };

    const handleRootClick = () => {
        if (currentIsRoot) return;
        setCurrentDirectoryId(null);
    }

    return (
        <Box>
            <Breadcrumb
                fontSize='large'
                display='flex'
                alignItems='center'
                sx={{
                    '& li': {
                        'alignSelf': 'flex-end',
                        'span': {
                            'alignSelf': 'flex-end'
                        }
                    }
                }}
            >
                <BreadcrumbItem>
                    <Heading
                        size='lg'
                        role={!currentIsRoot ? 'button' : undefined}
                        cursor={!currentIsRoot ? 'pointer' : undefined}
                        color={'gray.500'}
                        mr={4}
                        onClick={handleRootClick}
                    >
                        {t('my-library-title')}
                    </Heading>
                </BreadcrumbItem>
                {!currentIsRoot && !parentIsRoot && !parentParentIsRoot && (
                    <BreadcrumbItem>
                        <Flex dir='row' alignItems={'center'}>
                            <Text>...</Text>
                        </Flex>
                    </BreadcrumbItem>
                )}
                {!currentIsRoot && !parentIsRoot && (
                    <BreadcrumbItem>
                        <Flex dir='row' alignItems={'center'}>
                            <Flex
                                as='span'
                                role='button'
                                cursor='pointer'
                                alignItems={'center'}
                                onClick={handleUpClick}
                            >
                                <FaFolder color='gold'/> <Text ml={2}>{currentDirectory?.parent?.name}</Text>
                            </Flex>
                        </Flex>
                    </BreadcrumbItem>
                )}
                {!currentIsRoot &&
                    <BreadcrumbItem>
                        <Flex dir='row' alignItems={'center'}>
                            <FaFolder color='lightGray'/> <Text ml={2}>{currentDirectory?.name}</Text>
                        </Flex>
                    </BreadcrumbItem>
                }
            </Breadcrumb>
        </Box>
    );
};

export default DirectoryBreadCrumb;