import axios from "axios";
import { Conversation, ConversationHistory, ConversationInfo, MessageContext } from "../types/Conversation";

import { BASE_URL } from ".";
import { sseFetch } from "../utils/sseFetch";

const url = BASE_URL + "/conversations";

interface HistoryForTranscriptResponse {
	title: string;
	transcript: {
		sender: string;
		message: string;
		createdAt: string;
	}[];
}

export class ConversationService {
	public static async getConversations(): Promise<Conversation[]> {
		const response = await axios.get(url);
		return response.data;
	}

	// Endpoint for getting conversation history with files meta data
	public static async getConversationInfo(id: string): Promise<ConversationInfo> {
		const response = await axios.get(url + "/" + id);
		return response.data;
	}

	public static async initConversation(fileIds: string[]): Promise<Conversation> {
		const response = await axios.post(url, { fileIds });
		return response.data;
	}

	public static async addToConversation(
		id: string, 
		message: string, 
		isInspirationEnabled: boolean,
		messageContext: MessageContext,
		userLang: string,
		quoteSentenceIndexes: number[],
		onPartialBotResponse: (message: string) => void,
		onConversationHistoriesResponse: (response: ConversationHistory[]) => void,
		onStatusResponse: (status: string) => void
	): Promise<void> {
		await sseFetch(url + "/" + id, 
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ message, isInspirationEnabled, messageContext, userLang, quoteSentenceIndexes }),
				openWhenHidden: true,
				onmessage(msg) {
					switch (msg.event) {
						case 'bot':
							onPartialBotResponse(JSON.parse(msg.data));
							break;
						case 'conversationHistories':
							onConversationHistoriesResponse(JSON.parse(msg.data));
							break;
						case 'status':
							onStatusResponse(JSON.parse(msg.data));
							break;
						case 'error':
							throw new Error(msg.data);
						default:
							console.log("Unknown event", msg.event);
					}
				},
				onerror(err) {
					throw err; // Stop retrying
				}
			},
		);
	}

	public static async updateConversation(id: string, title: string) {
		const response = await axios.put(url + "/" + id, { title });
		return response.data;
	}

	public static async deleteConversation(id: string) {
		const response = await axios.delete(url + "/" + id);
		return response.data;
	}

	public static async deleteConversationMessage(messageId: string) {
		const response = await axios.delete(url + "/message/" + messageId);
		return response.data;
	}

	public static async getHistoryForTranscript(id: string) {
		const response = await axios.get<HistoryForTranscriptResponse>(url + "/" + id + "/transcript");
		return response.data;
	}
}