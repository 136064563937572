import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { DisabledTooltipText } from '../types/DisabledTooltipText';

type Props = {
	isDisabled: boolean;
	children: React.ReactNode;
	text?: DisabledTooltipText;
};

const DisabledFeatureTooltip = ({ isDisabled, children, text = DisabledTooltipText.StillProcessing }: Props) => {
	return (
		<Tooltip isDisabled={isDisabled} label={text}>{children}</Tooltip>
	);
};

export default DisabledFeatureTooltip;