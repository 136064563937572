import {
	Text,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	FormControl,
	FormLabel,
	Tooltip,
	Switch,
	Button,
	List,
	ListItem,
	ListIcon,
	Box,
	Menu,
	MenuButton,
	IconButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import { BiCommentAdd, BiMessageAltDetail } from "react-icons/bi";
import { formatDate } from "../../utils/dateTimeConversion";
import { DeleteIcon, DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Conversation } from "../../types/Conversation";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	conversations: Conversation[];
	currentConversation: Conversation | null;
	conversationOnClick: (conversation: Conversation) => void;
	showAllQA: boolean;
	setShowAllQA: (status: boolean) => void;
	addNewConversation: () => void;
	downloadTranscript: (conversationId: string) => void;
	deleteConversation: (conversationId: string) => void;
}

const SessionDrawer = ({
	isOpen,
	handleClose,
	conversations,
	currentConversation,
	conversationOnClick,
	showAllQA,
	setShowAllQA,
	addNewConversation,
	downloadTranscript,
	deleteConversation,
}: Props) => {
	const [isHovering, setIsHovering] = useState(false);
	const { t } = useTranslation();

	return (
		<Drawer
			placement={"right"}
			onClose={handleClose}
			isOpen={isOpen}
			size={"lg"}
		>
			<DrawerOverlay />
			<DrawerContent p={2}>
				<>
					<Flex flexDir={'row'} justifyContent={'flex-end'}>
						<Tooltip label={t('all-qa-tooltip')} isOpen={isHovering}>
							<FormControl
								display="flex"
								alignItems="center"
								onMouseEnter={() => setIsHovering(true)}
								onMouseLeave={() => setIsHovering(false)}
								w={'140px'}
							>
								<FormLabel htmlFor="allQA" mb="0">
									<Flex flexDir={"row"} alignItems={"center"}>
										<Text ml={"2"}>{t('all-study-sessions-label')}</Text>
									</Flex>
								</FormLabel>
								<Switch
									colorScheme="yellow"
									id="allQA"
									isChecked={showAllQA}
									onChange={(e) => setShowAllQA(e.target.checked)}
								/>
							</FormControl>
						</Tooltip>
					</Flex>
					<Button
						w="full"
						my={"3"}
						size={"md"}
						leftIcon={<BiCommentAdd />}
						onClick={addNewConversation}
						mb={"3"}
						border={"1px solid black"}
					>
						{t('study-sessions-label')}
					</Button>
				</>
				<List overflow={"auto"} h={"calc(100vh - 110px)"} size={"lg"}>
					{conversations.map((conversation) => (
						<ListItem
							key={conversation.id}
							p="2"
							cursor={"pointer"}
							bgColor={
								currentConversation?.id === conversation.id ? "gray.100" : "initial"
							}
							_hover={{ bg: "gray.200" }}
						>
							<Flex flexDir={"row"} justifyContent={"space-between"} alignItems={'center'}>
								<Flex
									direction="row"
									alignItems={"flex-start"}
									width="100%"
									onClick={() => conversationOnClick(conversation)}
								>
									<ListIcon
										as={BiMessageAltDetail}
										fontSize={"25px"}
										color="gray.500"
										mt={showAllQA ? 3 : 2}
									/>
									<Flex flexDir={"column"}>
										<Text
											fontSize="lg"
											overflow="ellipse"
											fontWeight={
												currentConversation?.id === conversation.id
													? "bold"
													: "normal"
											}
										>
											{conversation?.title}
										</Text>
										{showAllQA &&
											<Text
												fontSize="sm"
												overflow="ellipse"
												fontWeight={
													currentConversation?.id === conversation.id
														? "bold"
														: "normal"
												}
											>
												{conversation?.files[0]?.name}
											</Text>
										}

										<Text fontSize="sm" overflow="ellipse" color={"gray.400"}>
											{formatDate(conversation.updatedAt)}
										</Text>
									</Flex>
								</Flex>
								<Box>
									<Menu>
										<MenuButton
											as={IconButton}
											aria-label="Options"
											icon={<HamburgerIcon />}
											variant="outline"
											size={"lg"}
										/>
										<MenuList>
											<MenuItem
												icon={<DownloadIcon fontSize={"16px"} />}
												onClick={() => downloadTranscript(conversation.id)}
											>
												{t('download')}
											</MenuItem>
											<MenuItem
												icon={
													<DeleteIcon fontSize={"16px"} color={"red"} />
												}
												onClick={() => deleteConversation(conversation.id)}
											>
												{t('delete')}
											</MenuItem>
										</MenuList>
									</Menu>
								</Box>
							</Flex>
						</ListItem>
					))}
				</List>
			</DrawerContent>
		</Drawer>
	);
};

export default SessionDrawer;
